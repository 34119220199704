@charset "UTF-8";
html,
body {
  background-color: #fff;
  color: #000;
}

a {
  color: #40b3ff;
}

a:visited {
  color: #007dcf;
}

.mdc-banner__graphic {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-surface, #fff);
}

.mdc-banner__graphic {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
}

.mdc-banner__content,
.mdc-banner__fixed {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-banner__content,
  .mdc-banner__fixed {
    min-width: 100%;
  }
}

.mdc-banner__content {
  max-width: 720px;
}

.mdc-banner {
  z-index: 1;
  border-bottom-style: solid;
  box-sizing: border-box;
  display: none;
  flex-shrink: 0;
  height: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 480px) {
  .mdc-banner .mdc-banner__fixed {
    left: 0;
    right: 0;
  }
  .mdc-banner .mdc-banner__text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: 16px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 36px;
  }
  [dir=rtl] .mdc-banner .mdc-banner__text, .mdc-banner .mdc-banner__text[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    margin-left: 36px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 16px;
    /*rtl:end:ignore*/
  }
}
@media (max-width: 480px) {
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__content {
    flex-wrap: wrap;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__graphic {
    margin-bottom: 12px;
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: 16px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 8px;
    padding-bottom: 4px;
  }
  [dir=rtl] .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text, .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__text[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    margin-left: 8px;
    /* @noflip */ /*rtl:ignore*/
    margin-right: 16px;
    /*rtl:end:ignore*/
  }
  .mdc-banner.mdc-banner--mobile-stacked .mdc-banner__actions {
    margin-left: auto;
  }
}

.mdc-banner--opening,
.mdc-banner--open,
.mdc-banner--closing {
  display: flex;
}

.mdc-banner--open {
  transition: height 300ms ease;
}
.mdc-banner--open .mdc-banner__content {
  transition: transform 300ms ease;
  transform: translateY(0);
}

.mdc-banner--closing {
  transition: height 250ms ease;
}
.mdc-banner--closing .mdc-banner__content {
  transition: transform 250ms ease;
}

.mdc-banner--centered .mdc-banner__content {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.mdc-banner__fixed {
  border-bottom-style: solid;
  box-sizing: border-box;
  height: inherit;
  position: fixed;
  width: 100%;
}

.mdc-banner__content {
  display: flex;
  min-height: 52px;
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
}

.mdc-banner__graphic-text-wrapper {
  display: flex;
  width: 100%;
}

.mdc-banner__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  flex-shrink: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}
[dir=rtl] .mdc-banner__graphic, .mdc-banner__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-banner__icon {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.mdc-banner__text {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 90px;
  align-self: center;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}
[dir=rtl] .mdc-banner__text, .mdc-banner__text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 90px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-banner__actions {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  align-self: flex-end;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 8px;
  padding-top: 8px;
}
[dir=rtl] .mdc-banner__actions, .mdc-banner__actions[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-banner__secondary-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-banner__secondary-action, .mdc-banner__secondary-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-banner {
  background-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-radius: 0;
  /* @alternate */
}
.mdc-banner .mdc-banner__text {
  color: #000;
}
.mdc-banner .mdc-banner__text {
  letter-spacing: 0.0178571429em;
  font-size: 0.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.25rem;
}
.mdc-banner .mdc-banner__graphic {
  border-radius: 50%;
}
.mdc-banner .mdc-banner__graphic {
  height: 40px;
  width: 40px;
}
.mdc-banner .mdc-banner__fixed {
  background-color: #fff;
}
.mdc-banner .mdc-banner__fixed {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-banner .mdc-banner__fixed {
  border-bottom-width: 1px;
}
.mdc-banner .mdc-button:not(:disabled) {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-text-button-label-text-color, #ff3e00);
}
.mdc-banner .mdc-button .mdc-button__ripple::before,
.mdc-banner .mdc-button .mdc-button__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-text-button-hover-state-layer-color, #ff3e00);
}
.mdc-banner .mdc-button:hover .mdc-button__ripple::before, .mdc-banner .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-text-button-hover-state-layer-opacity, 0.04);
}
.mdc-banner .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-banner .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-focus-state-layer-opacity, 0.12);
}
.mdc-banner .mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-banner .mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.1);
}
.mdc-banner .mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.1);
}

.mdc-banner__secondary-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-banner__secondary-action, .mdc-banner__secondary-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.smui-banner--force-show {
  display: block !important;
}

.mdc-banner--closing,
.mdc-banner--opening {
  overflow-y: hidden;
}

.mdc-banner__actions {
  align-self: center;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-button {
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  background: transparent;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-button .mdc-button__label {
  position: relative;
}
.mdc-button .mdc-button__focus-ring {
  display: none;
}
@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(
      100% + 4px
    );
    width: calc(
      100% + 4px
    );
    display: block;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__focus-ring::after, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
}

.mdc-button {
  padding: 0 8px 0 8px;
}

.mdc-button--unelevated {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.mdc-button--unelevated.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}
.mdc-button--unelevated.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--raised {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 16px 0 16px;
}
.mdc-button--raised.mdc-button--icon-trailing {
  padding: 0 12px 0 16px;
}
.mdc-button--raised.mdc-button--icon-leading {
  padding: 0 16px 0 12px;
}

.mdc-button--outlined {
  border-style: solid;
  transition: border 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--outlined .mdc-button__ripple {
  border-style: solid;
  border-color: transparent;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-text-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-text-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-text-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-text-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-text-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-text-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}
.mdc-button:not(:disabled) {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-text-button-label-text-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-text-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}
.mdc-button .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-text-button-with-icon-icon-size, 1.125rem);
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-text-button-hover-state-layer-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-text-button-hover-state-layer-opacity, 0.04);
}
.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-focus-state-layer-opacity, 0.12);
}
.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-text-button-pressed-state-layer-opacity, 0.12);
}
.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-text-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--unelevated {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-filled-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-filled-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-filled-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-filled-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}
.mdc-button--unelevated:not(:disabled) {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-filled-button-container-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-filled-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-filled-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-filled-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}
.mdc-button--unelevated .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-filled-button-with-icon-icon-size, 1.125rem);
}
.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-filled-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-filled-button-hover-state-layer-opacity, 0.08);
}
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-focus-state-layer-opacity, 0.24);
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-filled-button-pressed-state-layer-opacity, 0.24);
}
.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-filled-button-container-shape, var(--mdc-shape-small, 4px));
}

.mdc-button--raised {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-protected-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-protected-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-protected-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-protected-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-protected-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
  --mdc-elevation-box-shadow-for-gss: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-button--raised:not(:disabled) {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-protected-button-container-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  background-color: var(--mdc-protected-button-disabled-container-color, rgba(0, 0, 0, 0.12));
}
.mdc-button--raised:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-protected-button-label-text-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-protected-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}
.mdc-button--raised .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-protected-button-with-icon-icon-size, 1.125rem);
}
.mdc-button--raised .mdc-button__ripple::before,
.mdc-button--raised .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-protected-button-hover-state-layer-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-protected-button-hover-state-layer-opacity, 0.08);
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-focus-state-layer-opacity, 0.24);
}
.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}
.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-protected-button-pressed-state-layer-opacity, 0.24);
}
.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-protected-button-container-shape, var(--mdc-shape-small, 4px));
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused, .mdc-button--raised:not(.mdc-ripple-upgraded):focus {
  --mdc-elevation-box-shadow-for-gss: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-focus-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-button--raised:hover {
  --mdc-elevation-box-shadow-for-gss: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-hover-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-button--raised:not(:disabled):active {
  --mdc-elevation-box-shadow-for-gss: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-pressed-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-button--raised:disabled {
  --mdc-elevation-box-shadow-for-gss: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-protected-button-disabled-container-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-button--outlined {
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-outlined-button-label-text-font, var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif)));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-label-text-size, var(--mdc-typography-button-font-size, 0.875rem));
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-outlined-button-label-text-tracking, var(--mdc-typography-button-letter-spacing, 0.0892857143em));
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-outlined-button-label-text-weight, var(--mdc-typography-button-font-weight, 500));
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-outlined-button-label-text-transform, var(--mdc-typography-button-text-transform, uppercase));
  height: 36px;
  /* @alternate */
  height: var(--mdc-outlined-button-container-height, 36px);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
  padding: 0 15px 0 15px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}
.mdc-button--outlined:not(:disabled) {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-outlined-button-label-text-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-outlined-button-disabled-label-text-color, rgba(0, 0, 0, 0.38));
}
.mdc-button--outlined .mdc-button__icon {
  font-size: 1.125rem;
  /* @alternate */
  font-size: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  width: 1.125rem;
  /* @alternate */
  width: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
  height: 1.125rem;
  /* @alternate */
  height: var(--mdc-outlined-button-with-icon-icon-size, 1.125rem);
}
.mdc-button--outlined .mdc-button__ripple::before,
.mdc-button--outlined .mdc-button__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-outlined-button-hover-state-layer-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-button--outlined:hover .mdc-button__ripple::before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-outlined-button-hover-state-layer-opacity, 0.04);
}
.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-focus-state-layer-opacity, 0.12);
}
.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}
.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-outlined-button-pressed-state-layer-opacity, 0.12);
}
.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-outlined-button-container-shape, var(--mdc-shape-small, 4px));
}
.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-outline-color, rgba(0, 0, 0, 0.12));
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-outlined-button-disabled-outline-color, rgba(0, 0, 0, 0.12));
}
.mdc-button--outlined.mdc-button--icon-trailing {
  padding: 0 11px 0 15px;
}
.mdc-button--outlined.mdc-button--icon-leading {
  padding: 0 15px 0 11px;
}
.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-width: 1px;
  /* @alternate */
  border-width: var(--mdc-outlined-button-outline-width, 1px);
}
.mdc-button--outlined .mdc-button__touch {
  left: calc(-1 * 1px);
  /* @alternate */
  left: calc(-1 * var(--mdc-outlined-button-outline-width, 1px));
  width: calc(100% + 2 * 1px);
  /* @alternate */
  width: calc(100% + 2 * var(--mdc-outlined-button-outline-width, 1px));
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
  /*rtl:end:ignore*/
}

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon, [dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl], .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-ripple-surface::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded],
.mdc-ripple-upgraded--unbounded {
  overflow: visible;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after,
.mdc-ripple-upgraded--unbounded::before,
.mdc-ripple-upgraded--unbounded::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::before,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after,
.mdc-ripple-upgraded--unbounded.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-ripple-surface:hover::before, .mdc-ripple-surface.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.smui-ripple-surface--primary::before, .smui-ripple-surface--primary::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.smui-ripple-surface--primary:hover::before, .smui-ripple-surface--primary.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.smui-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before, .smui-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.smui-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.smui-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.smui-ripple-surface--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.smui-ripple-surface--secondary::before, .smui-ripple-surface--secondary::after {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #676778));
}
.smui-ripple-surface--secondary:hover::before, .smui-ripple-surface--secondary.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.smui-ripple-surface--secondary.mdc-ripple-upgraded--background-focused::before, .smui-ripple-surface--secondary:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.smui-ripple-surface--secondary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.smui-ripple-surface--secondary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.smui-ripple-surface--secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.smui-button--color-secondary:not(:disabled) {
  color: #676778;
}
.smui-button--color-secondary:not(:disabled) .mdc-button__icon {
  color: #676778;
}
.smui-button--color-secondary.mdc-button--raised:not(:disabled), .smui-button--color-secondary.mdc-button--unelevated:not(:disabled) {
  background-color: #676778;
}
.smui-button--color-secondary.mdc-button--raised:not(:disabled), .smui-button--color-secondary.mdc-button--unelevated:not(:disabled) {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.smui-button--color-secondary.mdc-button--raised .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--raised .mdc-button__ripple::after, .smui-button--color-secondary.mdc-button--unelevated .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: white;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-text-primary-on-dark, white));
}
.smui-button--color-secondary.mdc-button--raised:hover .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--unelevated:hover .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.smui-button--color-secondary.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .smui-button--color-secondary.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.smui-button--color-secondary.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after, .smui-button--color-secondary.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.smui-button--color-secondary.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after, .smui-button--color-secondary.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.smui-button--color-secondary.mdc-button--raised.mdc-ripple-upgraded, .smui-button--color-secondary.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.smui-button--color-secondary.mdc-button--raised:not(:disabled), .smui-button--color-secondary.mdc-button--unelevated:not(:disabled) {
  color: #fff;
}
.smui-button--color-secondary.mdc-button--raised:not(:disabled) .mdc-button__icon, .smui-button--color-secondary.mdc-button--unelevated:not(:disabled) .mdc-button__icon {
  color: #fff;
}
.smui-button--color-secondary.mdc-button--outlined:not(:disabled) {
  border-color: #676778;
}
.smui-button--color-secondary .mdc-button__ripple::before, .smui-button--color-secondary .mdc-button__ripple::after {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #676778);
}
.smui-button--color-secondary:hover .mdc-button__ripple::before, .smui-button--color-secondary.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.smui-button--color-secondary.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .smui-button--color-secondary:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.smui-button--color-secondary:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.smui-button--color-secondary:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.smui-button--color-secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.smui-button__group {
  display: inline-flex;
}
.smui-button__group > .mdc-button, .smui-button__group > .smui-button__group-item > .mdc-button {
  margin-left: 0;
  margin-right: 0;
}
.smui-button__group > .mdc-button:not(:last-child), .smui-button__group > .mdc-button:not(:last-child) > .mdc-button__ripple, .smui-button__group > .smui-button__group-item:not(:last-child) > .mdc-button, .smui-button__group > .smui-button__group-item:not(:last-child) > .mdc-button > .mdc-button__ripple {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.smui-button__group > .mdc-button:not(:first-child), .smui-button__group > .mdc-button:not(:first-child) > .mdc-button__ripple, .smui-button__group > .smui-button__group-item:not(:first-child) > .mdc-button, .smui-button__group > .smui-button__group-item:not(:first-child) > .mdc-button > .mdc-button__ripple {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.smui-button__group.smui-button__group--raised {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.smui-button__group > .mdc-button--raised, .smui-button__group > .smui-button__group-item > .mdc-button--raised {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-button__group > .mdc-button--raised .mdc-button__ripple, .smui-button__group > .smui-button__group-item > .mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.smui-button__group > .mdc-button--raised:hover, .smui-button__group > .mdc-button--raised:focus, .smui-button__group > .smui-button__group-item > .mdc-button--raised:hover, .smui-button__group > .smui-button__group-item > .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-button__group > .mdc-button--raised:active, .smui-button__group > .smui-button__group-item > .mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-button__group > .mdc-button--raised:disabled, .smui-button__group > .smui-button__group-item > .mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-button__group > .mdc-button--outlined:not(:last-child), .smui-button__group > .smui-button__group-item:not(:last-child) > .mdc-button--outlined {
  border-right-width: 0;
}

.mdc-card {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-card::after {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-card::after {
    border-color: CanvasText;
  }
}

.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
.mdc-card--outlined::after {
  border: none;
}

.mdc-card__content {
  border-radius: inherit;
  height: 100%;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__action--button:last-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action .mdc-card__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-card__primary-action .mdc-card__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded .mdc-card__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation .mdc-card__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action .mdc-card__ripple::before,
.mdc-card__primary-action .mdc-card__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded .mdc-card__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action .mdc-card__ripple::before, .mdc-card__primary-action .mdc-card__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-card__primary-action:hover .mdc-card__ripple::before, .mdc-card__primary-action.mdc-ripple-surface--hover .mdc-card__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused .mdc-card__ripple::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus .mdc-card__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded) .mdc-card__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active .mdc-card__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-card__primary-action .mdc-card__ripple {
  box-sizing: content-box;
  height: 100%;
  overflow: hidden;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 5px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::after, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::after {
    border-color: CanvasText;
  }
}

.mdc-icon-button {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 48px;
  max-width: 48px;
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}
.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button--display-flex {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button__link {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button .mdc-icon-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button .mdc-icon-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before,
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button .mdc-icon-button__ripple::before, .mdc-icon-button .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover .mdc-icon-button__ripple::before, .mdc-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button .mdc-icon-button__ripple {
  height: 100%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.mdc-icon-button.smui-icon-button--size-mini {
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mdc-icon-button.smui-icon-button--size-mini svg,
.mdc-icon-button.smui-icon-button--size-mini img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button.smui-icon-button--size-mini .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-icon-button.smui-icon-button--size-mini.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-mini:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 40px;
  max-width: 40px;
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-mini.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-mini:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-mini.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-mini:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-mini.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button.smui-icon-button--size-mini:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-mini.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button.smui-icon-button--size-mini:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-icon-button.smui-icon-button--size-mini.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mdc-icon-button.smui-icon-button--size-mini.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-mini.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mdc-icon-button.smui-icon-button--size-mini .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button.smui-icon-button--size-button {
  width: 36px;
  height: 36px;
  padding: 9px;
  font-size: 18px;
  width: 36px;
  height: 36px;
  padding: 6px;
}
.mdc-icon-button.smui-icon-button--size-button svg,
.mdc-icon-button.smui-icon-button--size-button img {
  width: 18px;
  height: 18px;
}
.mdc-icon-button.smui-icon-button--size-button .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-icon-button.smui-icon-button--size-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 36px;
  max-width: 36px;
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button.smui-icon-button--size-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-icon-button.smui-icon-button--size-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-icon-button.smui-icon-button--size-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-icon-button.smui-icon-button--size-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mdc-icon-button.smui-icon-button--size-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button.smui-icon-button--size-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.mdc-icon-button.smui-icon-button--size-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}

.mdc-icon-button svg {
  pointer-events: none;
}

.smui-card--padded,
.smui-card__content,
.smui-card__primary-action--padded {
  padding: 16px;
}

.mdc-checkbox {
  padding: calc((40px - 18px) / 2);
  /* @alternate */
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((40px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-checkbox:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #676778));
}
.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #676778));
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((40px - 18px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((40px - 18px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - 40px) / 2);
  /* @alternate */
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - 40px) / 2);
  /* @alternate */
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - 40px) / 2);
  /* @alternate */
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: 40px;
  /* @alternate */
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: 40px;
  /* @alternate */
  height: var(--mdc-checkbox-touch-target-size, 40px);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #676778;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FF67677800000000FF676778 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #676778;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
    background-color: #676778;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FF67677800000000FF676778 {
  0%, 80% {
    border-color: #676778;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
    background-color: #676778;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #676778));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FF67677800000000FF676778;
}
.mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FF67677800000000FF676778;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  border-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
  background-color: transparent;
}
.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-checkbox-disabled-color, rgba(0, 0, 0, 0.38));
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff;
  /* @alternate */
  color: var(--mdc-checkbox-ink-color, #fff);
}
.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-checkbox-ink-color, #fff);
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__focus-ring::after, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__focus-ring::after {
    border-color: CanvasText;
  }
}
@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin: calc((48px - 40px) / 2);
  /* @alternate */
  margin: calc((var(--mdc-checkbox-state-layer-size, 48px) - var(--mdc-checkbox-state-layer-size, 40px)) / 2);
}
.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: calc((40px - 48px) / 2);
  /* @alternate */
  top: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  right: calc((40px - 48px) / 2);
  /* @alternate */
  right: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  left: calc((40px - 48px) / 2);
  /* @alternate */
  left: calc((var(--mdc-checkbox-state-layer-size, 40px) - var(--mdc-checkbox-state-layer-size, 48px)) / 2);
  width: 48px;
  /* @alternate */
  width: var(--mdc-checkbox-state-layer-size, 48px);
  height: 48px;
  /* @alternate */
  height: var(--mdc-checkbox-state-layer-size, 48px);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.mdc-checkbox__native-control[data-indeterminate=true] ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox {
  z-index: 0;
}
.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: GrayText;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-unselected-icon-color, GrayText);
    background-color: transparent;
  }
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled] ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: GrayText;
    /* @alternate */
    background-color: var(--mdc-checkbox-disabled-selected-icon-color, GrayText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: ButtonText;
    /* @alternate */
    color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: ButtonText;
    /* @alternate */
    border-color: var(--mdc-checkbox-selected-checkmark-color, ButtonText);
  }
  .mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: ButtonFace;
    /* @alternate */
    color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
  .mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: ButtonFace;
    /* @alternate */
    border-color: var(--mdc-checkbox-disabled-selected-checkmark-color, ButtonFace);
  }
}

.mdc-deprecated-chip-trailing-action__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-deprecated-chip-trailing-action {
  border: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__touch {
  width: 26px;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__icon {
  fill: currentColor;
  color: inherit;
}

.mdc-deprecated-chip-trailing-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--unbounded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-chip-trailing-action__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::before,
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded .mdc-deprecated-chip-trailing-action__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-deprecated-chip-trailing-action:hover .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action.mdc-ripple-surface--hover .mdc-deprecated-chip-trailing-action__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded--background-focused .mdc-deprecated-chip-trailing-action__ripple::before, .mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):focus .mdc-deprecated-chip-trailing-action__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded) .mdc-deprecated-chip-trailing-action__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-deprecated-chip-trailing-action:not(.mdc-ripple-upgraded):active .mdc-deprecated-chip-trailing-action__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-deprecated-chip-trailing-action .mdc-deprecated-chip-trailing-action__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-deprecated-chip-trailing-action {
  color: #000;
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}
.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-deprecated-chip-trailing-action__icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-deprecated-chip-trailing-action {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}
[dir=rtl] .mdc-deprecated-chip-trailing-action, .mdc-deprecated-chip-trailing-action[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-chip__icon--trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
}
[dir=rtl] .mdc-chip__icon--trailing, .mdc-chip__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-chip {
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  height: 32px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-width: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.mdc-chip .mdc-chip__ripple {
  border-radius: 16px;
}
.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */ /*rtl:ignore*/
  margin-left: -4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}
[dir=rtl] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, [dir=rtl] .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden), .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir=rtl], .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -4px;
  /*rtl:end:ignore*/
}

.mdc-chip .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-chip::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-chip .mdc-chip__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip__primary-action:focus {
  outline: none;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip__icon--leading,
.mdc-chip__icon--trailing {
  position: relative;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(255, 62, 0, 0.54);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #ff3e00;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #ff3e00);
}
.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-chip .mdc-chip__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-chip .mdc-chip__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-chip.mdc-ripple-upgraded--unbounded .mdc-chip__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-chip.mdc-ripple-upgraded--foreground-activation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-chip.mdc-ripple-upgraded--foreground-deactivation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-chip:hover .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-chip.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-chip:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-chip:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-chip .mdc-chip__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-surface--hover .mdc-chip__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.mdc-chip-set .mdc-chip {
  margin: 4px;
}
.mdc-chip-set .mdc-chip--touch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

.smui-chip-set--non-interactive .mdc-chip {
  cursor: initial;
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-circular-progress__determinate-circle,
.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #ff3e00;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #ff3e00);
}

.mdc-circular-progress__determinate-track {
  stroke: transparent;
}

@keyframes mdc-circular-progress-container-rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes mdc-circular-progress-color-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0.99;
  }
}
@keyframes mdc-circular-progress-color-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-color-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  to {
    opacity: 0;
  }
}
@keyframes mdc-circular-progress-left-spin {
  from {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(265deg);
  }
}
@keyframes mdc-circular-progress-right-spin {
  from {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg);
  }
  to {
    transform: rotate(-265deg);
  }
}
.mdc-circular-progress {
  display: inline-flex;
  position: relative;
  /* @noflip */ /*rtl:ignore*/
  direction: ltr;
  line-height: 0;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-circular-progress__determinate-container,
.mdc-circular-progress__indeterminate-circle-graphic,
.mdc-circular-progress__indeterminate-container,
.mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-container {
  transform: rotate(-90deg);
}

.mdc-circular-progress__indeterminate-container {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}

.mdc-circular-progress__determinate-circle-graphic,
.mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-circle {
  transition: stroke-dashoffset 500ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-circular-progress__gap-patch {
  position: absolute;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}
.mdc-circular-progress__gap-patch .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}

.mdc-circular-progress__circle-clipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
  width: 200%;
}

.mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  left: -100%;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__determinate-container {
  opacity: 0;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  opacity: 1;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear infinite;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-1 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-2 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-3 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__color-4 {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdc-circular-progress-color-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-left .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  /* @noflip */ /*rtl:ignore*/
  animation: mdc-circular-progress-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress--closed {
  opacity: 0;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: left top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: right top;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 1px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  content: "*";
}
[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.mdc-line-ripple::before {
  border-bottom-width: 1px;
}
.mdc-line-ripple::before {
  z-index: 1;
}
.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}
.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-left: none;
  /* @noflip */ /*rtl:ignore*/
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */ /*rtl:ignore*/
  border-right: none;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

.mdc-select {
  display: inline-flex;
  position: relative;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-select.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(255, 62, 0, 0.87);
}
.mdc-select.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.54);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #ff3e00;
  /* @alternate */
  fill: var(--mdc-theme-primary, #ff3e00);
}
.mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
  fill: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select.mdc-select--disabled + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-select.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select.mdc-select--disabled .mdc-select__selected-text {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__dropdown-icon {
    fill: red;
  }
  .mdc-select.mdc-select--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-notched-outline__leading,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__notch,
  .mdc-select.mdc-select--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
  .mdc-select.mdc-select--disabled .mdc-select__icon {
    color: GrayText;
  }
  .mdc-select.mdc-select--disabled + .mdc-select-helper-text {
    color: GrayText;
  }
}
.mdc-select .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.mdc-select .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-select .mdc-select__anchor, .mdc-select .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor, .mdc-select.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mdc-select .mdc-select__dropdown-icon {
  width: 24px;
  height: 24px;
}
.mdc-select .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item, .mdc-select .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}
[dir=rtl] .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic, .mdc-select .mdc-select__menu .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__dropdown-icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  display: inline-flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  pointer-events: none;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active,
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  position: absolute;
  top: 0;
  left: 0;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-graphic {
  width: 41.6666666667%;
  height: 20.8333333333%;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 1;
  transition: opacity 75ms linear 75ms;
}
.mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 0;
  transition: opacity 75ms linear;
}
[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-inactive {
  opacity: 0;
  transition: opacity 49.5ms linear;
}
.mdc-select--activated .mdc-select__dropdown-icon .mdc-select__dropdown-icon-active {
  opacity: 1;
  transition: opacity 100.5ms linear 49.5ms;
}

.mdc-select__anchor {
  width: 200px;
  min-width: 0;
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  cursor: pointer;
}
.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-select__selected-text-container {
  display: flex;
  appearance: none;
  pointer-events: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  height: 28px;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.mdc-select__selected-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-select__selected-text, .mdc-select__selected-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__dropdown-icon {
  fill: #b71c1c;
  /* @alternate */
  fill: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-select__dropdown-icon {
  fill: #b71c1c;
  /* @alternate */
  fill: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item, .mdc-select--with-leading-icon .mdc-select__menu .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-select__menu::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active), screen and (-ms-high-contrast: active) and (forced-colors: active) {
  .mdc-select__menu::before {
    border-color: CanvasText;
  }
}
.mdc-select__menu .mdc-deprecated-list .mdc-select__icon,
.mdc-select__menu .mdc-list .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-select__menu .mdc-deprecated-list .mdc-select__icon, [dir=rtl] .mdc-select__menu .mdc-list .mdc-select__icon, .mdc-select__menu .mdc-deprecated-list .mdc-select__icon[dir=rtl], .mdc-select__menu .mdc-list .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-select__menu .mdc-list .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-list-item__start {
  display: inline-flex;
  align-items: center;
}

.mdc-select__option {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-select__option, .mdc-select__option[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select__one-line-option.mdc-list-item--with-one-line {
  height: 48px;
}

.mdc-select__two-line-option.mdc-list-item--with-two-lines {
  height: 64px;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__start {
  margin-top: 20px;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-select__two-line-option.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-select__option-with-leading-content {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
.mdc-select__option-with-leading-content.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-select__option-with-leading-content.mdc-list-item, .mdc-select__option-with-leading-content.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-select__option-with-leading-content .mdc-list-item__start, .mdc-select__option-with-leading-content .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-leading-content .mdc-list-item__start {
  width: 36px;
  height: 24px;
}
[dir=rtl] .mdc-select__option-with-leading-content, .mdc-select__option-with-leading-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-select__option-with-meta.mdc-list-item, .mdc-select__option-with-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-select__option-with-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}
[dir=rtl] .mdc-select__option-with-meta .mdc-list-item__end, .mdc-select__option-with-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select--filled .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}
.mdc-select--filled .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select--filled.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.mdc-select--filled .mdc-select__anchor {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}
.mdc-select--filled.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select--filled:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--filled:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #ff3e00;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-select--filled.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-select--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-select--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-select--filled .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.mdc-select--filled.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}
.mdc-select--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-select--filled .mdc-floating-label, .mdc-select--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-select--filled.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--outlined {
  border: none;
}
.mdc-select--outlined .mdc-select__anchor {
  height: 56px;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-select--outlined .mdc-select__anchor {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined .mdc-select__anchor, .mdc-select--outlined .mdc-select__anchor[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}

@supports (top: max(0%)) {
  .mdc-select--outlined + .mdc-select-helper-text {
    /* @noflip */ /*rtl:ignore*/
    margin-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-select--outlined + .mdc-select-helper-text, .mdc-select--outlined + .mdc-select-helper-text[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    margin-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-select--outlined .mdc-select__anchor {
  display: flex;
  align-items: baseline;
  overflow: visible;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined 250ms 1;
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.mdc-select--outlined .mdc-select__anchor::before {
  display: none;
}
.mdc-select--outlined .mdc-select__selected-text-container {
  display: flex;
  border: none;
  z-index: 1;
  background-color: transparent;
}
.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}
.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__anchor:hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-select--outlined.mdc-select--with-leading-icon.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--shake, .mdc-select--outlined.mdc-select--with-leading-icon[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-56px-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-select--outlined.mdc-select--with-leading-icon .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 96px);
}
.mdc-select--outlined .mdc-menu-surface {
  margin-bottom: 8px;
}
.mdc-select--outlined.mdc-select--no-label .mdc-menu-surface,
.mdc-select--outlined .mdc-menu-surface--is-open-below {
  margin-bottom: 0;
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-select__anchor .mdc-select__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-select__anchor .mdc-select__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-select__anchor.mdc-ripple-upgraded--unbounded .mdc-select__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation .mdc-select__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-select__anchor.mdc-ripple-upgraded .mdc-select__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select__anchor .mdc-select__ripple::before,
.mdc-select__anchor .mdc-select__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-select__anchor:hover .mdc-select__ripple::before, .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__anchor .mdc-select__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-select__menu .mdc-deprecated-list .mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-select-helper-text {
  margin: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
[dir=rtl] .mdc-select-helper-text, .mdc-select-helper-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--validation-msg {
  opacity: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg,
.mdc-select-helper-text--validation-msg-persistent {
  opacity: 1;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  align-self: center;
  background-color: transparent;
  fill: currentColor;
}
.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.smui-floating-label--remove-transition {
  transition: unset !important;
}

.smui-floating-label--force-size {
  position: absolute !important;
  transform: unset !important;
}

.mdc-deprecated-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-deprecated-list:focus {
  outline: none;
}

.mdc-deprecated-list-item {
  height: 48px;
}

.mdc-deprecated-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-deprecated-list-item__graphic {
  background-color: transparent;
}

.mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text {
  opacity: 0.38;
}

.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__primary-text,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-deprecated-list-item--selected,
.mdc-deprecated-list-item--activated {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-deprecated-list-item--selected .mdc-deprecated-list-item__graphic,
.mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}

.mdc-deprecated-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-deprecated-list-item__wrapper {
  display: block;
}

.mdc-deprecated-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 48px;
}
.mdc-deprecated-list-item:focus {
  outline: none;
}
.mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item:not(.mdc-deprecated-list-item--selected):focus::before, .mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-deprecated-list-item.mdc-deprecated-list-item--selected::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-deprecated-list-item, .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--image-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--video-list .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list-item__graphic, .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--video-list .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-item__graphic {
  display: inline-flex;
}

.mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
.mdc-deprecated-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-deprecated-list-item[dir=rtl] .mdc-deprecated-list-item__meta, [dir=rtl] .mdc-deprecated-list-item .mdc-deprecated-list-item__meta {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
}

.mdc-deprecated-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-deprecated-list-item__text[for] {
  pointer-events: none;
}

.mdc-deprecated-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::before, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--image-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-item__primary-text::after, .mdc-deprecated-list--icon-list .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-deprecated-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-deprecated-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-deprecated-list--dense .mdc-deprecated-list-item__secondary-text {
  font-size: inherit;
}

.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 40px;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.mdc-deprecated-list--two-line .mdc-deprecated-list-item {
  height: 64px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--video-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--image-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--avatar-list .mdc-deprecated-list-item, .mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item {
  height: 72px;
}
.mdc-deprecated-list--two-line.mdc-deprecated-list--icon-list .mdc-deprecated-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-deprecated-list--two-line.mdc-deprecated-list--dense .mdc-deprecated-list-item,
.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 60px;
}

.mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic, .mdc-deprecated-list--avatar-list.mdc-deprecated-list--dense .mdc-deprecated-list-item__graphic[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  cursor: pointer;
}

a.mdc-deprecated-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-deprecated-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-deprecated-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset, .mdc-deprecated-list-divider--inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded, .mdc-deprecated-list-divider--inset.mdc-deprecated-list-divider--padded[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--icon-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--avatar-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 72px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 72px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--thumbnail-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 88px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 88px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--image-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 116px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 116px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding, .mdc-deprecated-list--video-list .mdc-deprecated-list-divider--inset-leading.mdc-deprecated-list-divider--inset-trailing.mdc-deprecated-list-divider--inset-padding[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-deprecated-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}

.mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item__wrapper {
  display: block;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  align-items: stretch;
  cursor: pointer;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-list-item.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive {
  cursor: auto;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected::before {
    border-color: CanvasText;
  }
}
.mdc-list-item.mdc-list-item--selected:focus::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-list-item.mdc-list-item--selected:focus::before {
    border-color: CanvasText;
  }
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__end {
  flex-shrink: 0;
  pointer-events: none;
}

.mdc-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
  pointer-events: none;
}
.mdc-list-item--with-two-lines .mdc-list-item__content, .mdc-list-item--with-three-lines .mdc-list-item__content {
  align-self: stretch;
}
.mdc-list-item__content[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after, .mdc-list-item--with-three-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-three-lines .mdc-list-item__secondary-text {
  white-space: normal;
  line-height: 20px;
}
.mdc-list-item--with-overline .mdc-list-item__secondary-text {
  white-space: nowrap;
  line-height: auto;
}

.mdc-list-item__overline-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-three-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item--with-leading-avatar.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-avatar.mdc-list-item, .mdc-list-item--with-leading-avatar.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-avatar .mdc-list-item__start, .mdc-list-item--with-leading-avatar .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}
.mdc-list-item--with-leading-avatar .mdc-list-item__start {
  border-radius: 50%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  width: 24px;
  height: 24px;
}
.mdc-list-item--with-leading-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-icon.mdc-list-item, .mdc-list-item--with-leading-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 32px;
}
[dir=rtl] .mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-leading-icon .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 32px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-thumbnail.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail.mdc-list-item, .mdc-list-item--with-leading-thumbnail.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-thumbnail .mdc-list-item__start, .mdc-list-item--with-leading-thumbnail .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-thumbnail .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-image.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-image.mdc-list-item, .mdc-list-item--with-leading-image.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-image .mdc-list-item__start, .mdc-list-item--with-leading-image .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-image .mdc-list-item__start {
  width: 56px;
  height: 56px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-video.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-video.mdc-list-item, .mdc-list-item--with-leading-video.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-video .mdc-list-item__start, .mdc-list-item--with-leading-video .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-video .mdc-list-item__start {
  width: 100px;
  height: 56px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox.mdc-list-item, .mdc-list-item--with-leading-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-checkbox .mdc-list-item__start, .mdc-list-item--with-leading-checkbox .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-radio.mdc-list-item, .mdc-list-item--with-leading-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
}
[dir=rtl] .mdc-list-item--with-leading-radio .mdc-list-item__start, .mdc-list-item--with-leading-radio .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-radio .mdc-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-leading-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-item--with-leading-switch.mdc-list-item, .mdc-list-item--with-leading-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-leading-switch .mdc-list-item__start, .mdc-list-item--with-leading-switch .mdc-list-item__start[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-leading-switch .mdc-list-item__start {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines .mdc-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines {
  height: 72px;
}

.mdc-list-item--with-trailing-icon.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-icon.mdc-list-item, .mdc-list-item--with-trailing-icon.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--with-trailing-icon .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-meta.mdc-list-item, .mdc-list-item--with-trailing-meta.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 28px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-meta .mdc-list-item__end, .mdc-list-item--with-trailing-meta .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 28px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-trailing-meta.mdc-list-item--with-three-lines .mdc-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox.mdc-list-item, .mdc-list-item--with-trailing-checkbox.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-checkbox .mdc-list-item__end, .mdc-list-item--with-trailing-checkbox .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-checkbox.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-radio.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-radio.mdc-list-item, .mdc-list-item--with-trailing-radio.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-list-item--with-trailing-radio .mdc-list-item__end, .mdc-list-item--with-trailing-radio .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 24px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-radio .mdc-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-list-item--with-trailing-radio.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-list-item--with-trailing-switch.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-list-item--with-trailing-switch.mdc-list-item, .mdc-list-item--with-trailing-switch.mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
}
[dir=rtl] .mdc-list-item--with-trailing-switch .mdc-list-item__end, .mdc-list-item--with-trailing-switch .mdc-list-item__end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-item--with-trailing-switch .mdc-list-item__end {
  width: 36px;
  height: 20px;
}
.mdc-list-item--with-trailing-switch.mdc-list-item--with-three-lines .mdc-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-overline.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item--with-overline.mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-group .mdc-deprecated-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider {
  height: 1px;
}

.mdc-list-divider {
  padding: 0;
  background-clip: content-box;
}

.mdc-list-divider.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset, .mdc-list-divider.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-leading-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-list-divider.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset,
.mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-list-divider.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset, [dir=rtl] .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset, .mdc-list-divider.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-text.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-icon.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-image.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-thumbnail.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-avatar.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-checkbox.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-switch.mdc-list-divider--with-trailing-inset[dir=rtl], .mdc-list-divider--with-leading-radio.mdc-list-divider--with-trailing-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
  /*rtl:end:ignore*/
}

.mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: auto;
}
[dir=rtl] .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset, .mdc-list-divider--with-leading-video.mdc-list-divider--with-leading-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-list-divider, .mdc-list-divider[dir=rtl] {
  /*rtl:begin:ignore*/
  padding: 0;
  /*rtl:end:ignore*/
}

:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-deprecated-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated .mdc-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-deprecated-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-deprecated-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-deprecated-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-deprecated-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected .mdc-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-deprecated-list-item__ripple,
:not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-deprecated-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-deprecated-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-deprecated-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-deprecated-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::before,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-deprecated-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-deprecated-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-deprecated-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-deprecated-list-item--disabled .mdc-deprecated-list-item__ripple,
.mdc-deprecated-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/*
 Temporary addition until support for three-line added to @material
https://github.com/material-components/material-components-web/issues/31
*/
.smui-list--three-line .mdc-deprecated-list-item__text {
  align-self: flex-start;
}

.smui-list--three-line .mdc-deprecated-list-item {
  height: 88px;
}

.smui-list--three-line.mdc-deprecated-list--dense .mdc-deprecated-list-item {
  height: 76px;
}

.mdc-deprecated-list-item.smui-menu-item--non-interactive {
  cursor: auto;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  /* @alternate */
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  /* @alternate */
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top left;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top right;
}
.mdc-menu-surface:focus {
  outline: none;
}
.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}
[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform-origin-left: top right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin-right: top left;
  /*rtl:end:ignore*/
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.smui-menu-surface--static {
  position: static;
  z-index: 0;
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}
.mdc-menu .mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-menu-item--submenu-open .mdc-deprecated-list-item__ripple::before {
  opacity: 0.04;
}
.mdc-menu .mdc-menu-item--submenu-open .mdc-list-item__ripple::before {
  opacity: 0.04;
}
.mdc-menu .mdc-deprecated-list {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list,
.mdc-menu .mdc-list {
  /* @alternate */
  position: relative;
}
.mdc-menu .mdc-deprecated-list .mdc-elevation-overlay,
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-menu .mdc-deprecated-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-deprecated-list-item {
  user-select: none;
}
.mdc-menu .mdc-deprecated-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__text,
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-deprecated-list-item {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 56px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-deprecated-list-item, .mdc-menu__selection-group .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 56px;
  /*rtl:end:ignore*/
}

.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-menu__selection-group .mdc-list-item__graphic.mdc-menu__selection-group-icon {
  display: none;
}

.mdc-menu-item--selected .mdc-list-item__graphic.mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-select--activated {
  z-index: 8;
}

.smui-select--standard .mdc-select__dropdown-icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 26px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .smui-select--standard .mdc-select__dropdown-icon, .smui-select--standard .mdc-select__dropdown-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 26px;
  /*rtl:end:ignore*/
}

.smui-select--standard.mdc-select--with-leading-icon .mdc-select__icon {
  position: relative;
  margin: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: -24px;
  /* @noflip */ /*rtl:ignore*/
  left: -36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-select--standard.mdc-select--with-leading-icon .mdc-select__icon, .smui-select--standard.mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: -24px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .smui-select--standard.mdc-select--with-leading-icon .mdc-select__icon, .smui-select--standard.mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: -36px;
  /*rtl:end:ignore*/
}

.smui-select--standard .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .smui-select--standard .mdc-select__anchor, .smui-select--standard .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.smui-select--standard.mdc-select--with-leading-icon .mdc-select__anchor {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 36px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .smui-select--standard.mdc-select--with-leading-icon .mdc-select__anchor, .smui-select--standard.mdc-select--with-leading-icon .mdc-select__anchor[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 36px;
  /*rtl:end:ignore*/
}

.smui-select--standard .mdc-select__anchor .mdc-select__ripple::before,
.smui-select--standard .mdc-select__anchor .mdc-select__ripple::after {
  background-color: transparent;
  /* @alternate */
  background-color: var(--mdc-ripple-color, transparent);
}
.smui-select--standard .mdc-select__anchor:hover .mdc-select__ripple::before, .smui-select--standard .mdc-select__anchor.mdc-ripple-surface--hover .mdc-select__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.smui-select--standard .mdc-select__anchor.mdc-ripple-upgraded--background-focused .mdc-select__ripple::before, .smui-select--standard .mdc-select__anchor:not(.mdc-ripple-upgraded):focus .mdc-select__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.smui-select--standard .mdc-select__anchor {
  height: 56px;
  display: flex;
  align-items: baseline;
}
.smui-select--standard .mdc-select__anchor::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.smui-select--standard.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text::before {
  content: "​";
}
.smui-select--standard.mdc-select--no-label .mdc-select__anchor .mdc-select__selected-text-container {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.smui-select--standard.mdc-select--no-label .mdc-select__anchor::before {
  display: none;
}
.smui-select--standard:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}
.smui-select--standard.mdc-select--disabled .mdc-select__anchor {
  background-color: transparent;
}
.smui-select--standard:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.smui-select--standard:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.smui-select--standard:not(.mdc-select--disabled) .mdc-line-ripple::after {
  border-bottom-color: #ff3e00;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #ff3e00);
}
.smui-select--standard.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.smui-select--standard .mdc-floating-label {
  max-width: calc(100% - 52px);
}
.smui-select--standard .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 52px / 0.75);
}
.smui-select--standard .mdc-menu-surface--is-open-below {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.smui-select--standard.mdc-select--focused.mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}
.smui-select--standard .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-select--standard .mdc-floating-label, .smui-select--standard .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.smui-select--standard.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-select--standard.mdc-select--with-leading-icon .mdc-floating-label, .smui-select--standard.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.smui-select--standard.mdc-select--with-leading-icon .mdc-floating-label {
  max-width: calc(100% - 72px);
}
.smui-select--standard.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 72px / 0.75);
}
.smui-select--standard + .mdc-select-helper-text {
  margin-left: 0;
  margin-right: 0;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-data-table {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.mdc-data-table .mdc-data-table__header-cell:first-child {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:first-child, .mdc-data-table .mdc-data-table__header-cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
  border-top-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table .mdc-data-table__header-cell:last-child {
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table .mdc-data-table__header-cell:last-child, .mdc-data-table .mdc-data-table__header-cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-medium, 4px);
  border-top-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child {
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child {
  border-bottom-right-radius: 4px;
  /* @alternate */
  border-bottom-right-radius: var(--mdc-shape-medium, 4px);
}
[dir=rtl] .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child, .mdc-data-table.mdc-data-table--without-footer .mdc-data-table__row:last-child .mdc-data-table__cell:last-child[dir=rtl] {
  /*rtl:begin:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  border-bottom-left-radius: var(--mdc-shape-medium, 4px);
  border-bottom-right-radius: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(255, 62, 0, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-data-table__pagination {
  border-top-width: 1px;
  border-top-style: solid;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none;
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__row {
  height: 52px;
}

.mdc-data-table__pagination {
  min-height: 52px;
}

.mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 16px 0 16px;
}

.mdc-data-table__header-cell--checkbox,
.mdc-data-table__cell--checkbox {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-data-table__header-cell--checkbox, [dir=rtl] .mdc-data-table__cell--checkbox, .mdc-data-table__header-cell--checkbox[dir=rtl], .mdc-data-table__cell--checkbox[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__table-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.mdc-data-table__table {
  min-width: 100%;
  border: 0;
  white-space: nowrap;
  border-spacing: 0;
  /**
   * With table-layout:fixed, table and column widths are defined by the width
   * of the first row of cells. Cells in subsequent rows do not affect column
   * widths. This results in a predictable table layout and may also speed up
   * rendering.
   */
  table-layout: fixed;
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
[dir=rtl] .mdc-data-table__cell, .mdc-data-table__cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__cell--numeric, .mdc-data-table__cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-data-table__header-cell, .mdc-data-table__header-cell[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--checkbox {
  width: 1px;
}

.mdc-data-table__header-cell--numeric {
  text-align: right;
}
[dir=rtl] .mdc-data-table__header-cell--numeric, .mdc-data-table__header-cell--numeric[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-data-table__sort-icon-button {
  width: 28px;
  height: 28px;
  padding: 2px;
  transform: rotate(0.0001deg);
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  transition: transform 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}
.mdc-data-table__sort-icon-button .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 28px;
  max-width: 28px;
}
@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 28px;
  height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-data-table__sort-icon-button.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 28px;
  max-width: 28px;
}
.mdc-data-table__sort-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 28px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 28px;
  transform: translate(-50%, -50%);
}
[dir=rtl] .mdc-data-table__sort-icon-button, .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--numeric .mdc-data-table__sort-icon-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__header-cell--sorted-descending .mdc-data-table__sort-icon-button {
  transform: rotate(-180deg);
}
.mdc-data-table__sort-icon-button:focus, .mdc-data-table__header-cell:hover .mdc-data-table__sort-icon-button, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  opacity: 1;
}

.mdc-data-table__header-cell-wrapper {
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}

.mdc-data-table__header-cell--with-sort {
  cursor: pointer;
}

.mdc-data-table__sort-status-label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

.mdc-data-table--sticky-header .mdc-data-table__header-cell {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.6));
}
.mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button .mdc-icon-button__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:hover .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-cell--sorted .mdc-data-table__sort-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-data-table__progress-indicator {
  display: none;
  position: absolute;
  width: 100%;
}
.mdc-data-table--in-progress .mdc-data-table__progress-indicator {
  display: block;
}

.mdc-data-table__scrim {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  height: 100%;
  opacity: 0.32;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-data-table__pagination {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.mdc-data-table__pagination-trailing {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
[dir=rtl] .mdc-data-table__pagination-trailing, .mdc-data-table__pagination-trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-navigation {
  align-items: center;
  display: flex;
}

.mdc-data-table__pagination-button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 4px;
}
[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: rotate(180deg);
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-data-table__pagination-button, .mdc-data-table__pagination-button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-total {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 14px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 36px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-total, .mdc-data-table__pagination-total[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 36px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 14px;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 22px;
  align-items: center;
  display: inline-flex;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page, .mdc-data-table__pagination-rows-per-page[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 22px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  white-space: nowrap;
}
[dir=rtl] .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__pagination-rows-per-page-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-data-table__pagination-rows-per-page-select {
  min-width: 80px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 80px);
  margin: 8px 0;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  width: 100%;
  min-width: 80px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor {
  height: 36px;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-36px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-36px {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-data-table__pagination-rows-per-page-select .mdc-select__dropdown-icon {
  width: 20px;
  height: 20px;
}
.mdc-data-table__pagination-rows-per-page-select.mdc-select--outlined .mdc-select__anchor :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 56px);
}
.mdc-data-table__pagination-rows-per-page-select .mdc-list-item.mdc-list-item--with-one-line {
  height: 36px;
}

.mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-surface--hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded,
.mdc-data-table__row-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__header-row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-data-table__row-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
  background-color: transparent;
}
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__header-row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled ~ .mdc-checkbox__background {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
}
@keyframes mdc-checkbox-fade-in-background-8A000000FFFF3E0000000000FFFF3E00 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
  50% {
    border-color: #ff3e00;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
    background-color: #ff3e00;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000FFFF3E0000000000FFFF3E00 {
  0%, 80% {
    border-color: #ff3e00;
    /* @alternate */
    border-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
    background-color: #ff3e00;
    /* @alternate */
    background-color: var(--mdc-checkbox-checked-color, var(--mdc-theme-secondary, #ff3e00));
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    border-color: var(--mdc-checkbox-unchecked-color, rgba(0, 0, 0, 0.54));
    background-color: transparent;
  }
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000FFFF3E0000000000FFFF3E00;
}
.mdc-data-table__header-row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-data-table__header-row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background,
.mdc-data-table__row-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000FFFF3E0000000000FFFF3E00;
}

.mdc-data-table--sticky-header > .mdc-data-table__table-container {
  overflow-x: unset;
}

.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__surface-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog .mdc-dialog__close {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-surface, #000));
}
.mdc-dialog .mdc-dialog__close:hover .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-dialog .mdc-dialog__close:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog .mdc-dialog__close.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}

.mdc-dialog__surface {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-dialog__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-dialog__title-icon {
  /** Hook for theming API. */
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
  max-width: none;
}
@media (max-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    width: 560px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (max-width: 600px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    order: -1;
    left: -12px;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__header {
    padding: 0 16px 9px;
    justify-content: flex-start;
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__title {
    margin-left: calc(16px - 2 * 12px);
  }
}
@media (min-width: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: calc(100vw - 400px);
  }
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface .mdc-dialog__close {
    right: -12px;
  }
}
.mdc-dialog.mdc-dialog__scrim--hidden .mdc-dialog__scrim {
  opacity: 0;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
[dir=rtl] .mdc-dialog__surface, .mdc-dialog__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-dialog__surface {
    outline: 2px solid windowText;
  }
}
.mdc-dialog__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-dialog__surface::before {
    border-color: CanvasText;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .mdc-dialog__surface::before {
    content: none;
  }
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
  padding: 0 24px 9px;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-dialog__title, .mdc-dialog__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-dialog--scrollable .mdc-dialog__title {
  margin-bottom: 1px;
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  align-items: baseline;
  border-bottom: 1px solid transparent;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 24px 9px;
  z-index: 1;
}
@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen .mdc-dialog__header {
    border-bottom-color: CanvasText;
  }
}
.mdc-dialog--fullscreen .mdc-dialog__header .mdc-dialog__close {
  right: -12px;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  margin-bottom: 0;
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__title {
  border-bottom: 0;
  margin-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  top: 5px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}
@media screen and (forced-colors: active) {
  .mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}

.mdc-dialog__content {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-deprecated-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
@media screen and (forced-colors: active) {
  .mdc-dialog__actions {
    border-top-color: CanvasText;
  }
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  max-width: 100%;
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-dialog__button:first-child {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim {
  opacity: 1;
  z-index: 1;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  transition: opacity 75ms linear;
}
.mdc-dialog--open.mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog__surface-scrim {
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-dialog__surface-scrim--shown .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--showing .mdc-dialog__surface-scrim, .mdc-dialog__surface-scrim--hiding .mdc-dialog__surface-scrim {
  display: block;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-dialog--no-content-padding .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog--sheet .mdc-dialog__close {
  right: 12px;
  top: 9px;
  position: absolute;
  z-index: 1;
}

.mdc-dialog.smui-dialog--selection .mdc-dialog__content {
  padding: 0;
}

.mdc-dialog--stacked .mdc-dialog__actions.smui-dialog__actions--reversed {
  flex-direction: column-reverse;
}
.mdc-dialog--stacked .mdc-dialog__actions.smui-dialog__actions--reversed .mdc-dialog__button:not(:last-child) {
  margin-top: 12px;
}
.mdc-dialog--stacked .mdc-dialog__actions.smui-dialog__actions--reversed .mdc-dialog__button:last-child {
  margin-top: 0;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-deprecated-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-deprecated-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-deprecated-list-item--activated .mdc-deprecated-list-item__graphic {
  color: #ff3e00;
}
.mdc-drawer .mdc-deprecated-list-item--activated {
  color: rgba(255, 62, 0, 0.87);
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-large, 0);
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 256px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 256px;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-right-width: 0;
  /* @noflip */ /*rtl:ignore*/
  border-left-width: 1px;
  /* @noflip */ /*rtl:ignore*/
  border-right-style: none;
  /* @noflip */ /*rtl:ignore*/
  border-left-style: solid;
  /*rtl:end:ignore*/
}

.mdc-drawer .mdc-deprecated-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}
.mdc-drawer .mdc-deprecated-list-item:nth-child(1) {
  margin-top: 2px;
}
.mdc-drawer .mdc-deprecated-list-item:nth-last-child(1) {
  margin-bottom: 0;
}
.mdc-drawer .mdc-deprecated-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}
.mdc-drawer .mdc-deprecated-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer .mdc-deprecated-list-divider {
  margin: 3px 0 4px;
}
.mdc-drawer .mdc-deprecated-list-item__text,
.mdc-drawer .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}
[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}
[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(0);
  /*rtl:end:ignore*/
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}
[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}
.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  position: relative;
}
[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  display: none;
  position: fixed;
}
.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}
.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}
.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}
.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-drawer.smui-drawer__absolute,
.mdc-drawer-scrim.smui-drawer-scrim__absolute {
  position: absolute;
}

.mdc-fab {
  /* @alternate */
  position: relative;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: visible;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-fab .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-fab::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-fab:hover {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-fab.mdc-ripple-upgraded--background-focused, .mdc-fab:not(.mdc-ripple-upgraded):focus {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-fab .mdc-fab__focus-ring {
  position: absolute;
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}
@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__focus-ring::after, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-fab:active, .mdc-fab:focus:active {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mdc-fab:active, .mdc-fab:focus {
  outline: none;
}
.mdc-fab:hover {
  cursor: pointer;
}
.mdc-fab > svg {
  width: 100%;
}

.mdc-fab--mini {
  width: 40px;
  height: 40px;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  border-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  width: auto;
  max-width: 100%;
  height: 48px;
  /* @alternate */
  line-height: normal;
}
.mdc-fab--extended .mdc-fab__ripple {
  border-radius: 24px;
}
.mdc-fab--extended .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
}
[dir=rtl] .mdc-fab--extended .mdc-fab__icon, .mdc-fab--extended .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
  /*rtl:end:ignore*/
}

.mdc-fab--extended .mdc-fab__label + .mdc-fab__icon {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: calc(12px - 20px);
}
[dir=rtl] .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon, .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: calc(12px - 20px);
  /* @noflip */ /*rtl:ignore*/
  margin-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-fab--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-fab--touch .mdc-fab__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mdc-fab::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-fab::before {
    border-color: CanvasText;
  }
}

.mdc-fab__label {
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
}

.mdc-fab__icon {
  transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
  fill: currentColor;
  will-change: transform;
}

.mdc-fab .mdc-fab__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-fab--exited {
  transform: scale(0);
  opacity: 0;
  transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
}
.mdc-fab--exited .mdc-fab__icon {
  transform: scale(0);
  transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-fab {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #676778);
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mdc-fab .mdc-fab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mdc-fab, .mdc-fab:not(:disabled) .mdc-fab__icon, .mdc-fab:not(:disabled) .mdc-fab__label, .mdc-fab:disabled .mdc-fab__icon, .mdc-fab:disabled .mdc-fab__label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}
.mdc-fab:not(.mdc-fab--extended) {
  border-radius: 50%;
}
.mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
  border-radius: 50%;
}

.mdc-fab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-fab .mdc-fab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-fab .mdc-fab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-fab.mdc-ripple-upgraded--unbounded .mdc-fab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-fab.mdc-ripple-upgraded--foreground-activation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-fab.mdc-ripple-upgraded--foreground-deactivation .mdc-fab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-fab.mdc-ripple-upgraded .mdc-fab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-fab .mdc-fab__ripple::before, .mdc-fab .mdc-fab__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-secondary, #fff));
}
.mdc-fab:hover .mdc-fab__ripple::before, .mdc-fab.mdc-ripple-surface--hover .mdc-fab__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__ripple::before, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-fab:not(.mdc-ripple-upgraded) .mdc-fab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-fab:not(.mdc-ripple-upgraded):active .mdc-fab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-fab .mdc-fab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.mdc-fab {
  z-index: 0;
}
.mdc-fab .mdc-fab__ripple::before,
.mdc-fab .mdc-fab__ripple::after {
  z-index: -1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, -1);
}

.smui-fab--color-primary {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
}
.smui-fab--color-primary, .smui-fab--color-primary:not(:disabled) .mdc-fab__icon, .smui-fab--color-primary:not(:disabled) .mdc-fab__label, .smui-fab--color-primary:disabled .mdc-fab__icon, .smui-fab--color-primary:disabled .mdc-fab__label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

a.mdc-fab, a.mdc-fab:not(:disabled) .mdc-fab__icon, a.mdc-fab:not(:disabled) .mdc-fab__label, a.mdc-fab:disabled .mdc-fab__icon, a.mdc-fab:disabled .mdc-fab__label, a.mdc-fab:visited, a.mdc-fab:visited:not(:disabled) .mdc-fab__icon, a.mdc-fab:visited:not(:disabled) .mdc-fab__label, a.mdc-fab:visited:disabled .mdc-fab__icon, a.mdc-fab:visited:disabled .mdc-fab__label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}

a.smui-fab--color-primary, a.smui-fab--color-primary:not(:disabled) .mdc-fab__icon, a.smui-fab--color-primary:not(:disabled) .mdc-fab__label, a.smui-fab--color-primary:disabled .mdc-fab__icon, a.smui-fab--color-primary:disabled .mdc-fab__label, a.smui-fab--color-primary:visited, a.smui-fab--color-primary:visited:not(:disabled) .mdc-fab__icon, a.smui-fab--color-primary:visited:not(:disabled) .mdc-fab__label, a.smui-fab--color-primary:visited:disabled .mdc-fab__icon, a.smui-fab--color-primary:visited:disabled .mdc-fab__label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-fab .mdc-fab__ripple::before, .mdc-fab .mdc-fab__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-secondary, #fff));
}
.mdc-fab:hover .mdc-fab__ripple::before, .mdc-fab.mdc-ripple-surface--hover .mdc-fab__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-fab.mdc-ripple-upgraded--background-focused .mdc-fab__ripple::before, .mdc-fab:not(.mdc-ripple-upgraded):focus .mdc-fab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-fab:not(.mdc-ripple-upgraded) .mdc-fab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-fab:not(.mdc-ripple-upgraded):active .mdc-fab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.smui-fab--color-primary .mdc-fab__ripple::before, .smui-fab--color-primary .mdc-fab__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.smui-fab--color-primary:hover .mdc-fab__ripple::before, .smui-fab--color-primary.mdc-ripple-surface--hover .mdc-fab__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.smui-fab--color-primary.mdc-ripple-upgraded--background-focused .mdc-fab__ripple::before, .smui-fab--color-primary:not(.mdc-ripple-upgraded):focus .mdc-fab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.smui-fab--color-primary:not(.mdc-ripple-upgraded) .mdc-fab__ripple::after {
  transition: opacity 150ms linear;
}
.smui-fab--color-primary:not(.mdc-ripple-upgraded):active .mdc-fab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.smui-fab--color-primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  order: 0;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  /*rtl:end:ignore*/
}

.mdc-form-field--nowrap > label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mdc-form-field--align-end > label {
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 4px;
  order: -1;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 4px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-form-field--space-between {
  justify-content: space-between;
}
.mdc-form-field--space-between > label {
  margin: 0;
}
[dir=rtl] .mdc-form-field--space-between > label, .mdc-form-field--space-between > label[dir=rtl] {
  /*rtl:begin:ignore*/
  margin: 0;
  /*rtl:end:ignore*/
}

.mdc-image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
}

.mdc-image-list__item,
.mdc-image-list__image-aspect-container {
  position: relative;
  box-sizing: border-box;
}

.mdc-image-list__item {
  list-style-type: none;
}

.mdc-image-list__image {
  width: 100%;
}

.mdc-image-list__image-aspect-container .mdc-image-list__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1);
}

.mdc-image-list__image {
  border-radius: 0;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mdc-image-list__supporting {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 0;
  line-height: 24px;
}

.mdc-image-list__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.mdc-image-list--masonry {
  display: block;
}
.mdc-image-list--masonry .mdc-image-list__item {
  break-inside: avoid-column;
}
.mdc-image-list--masonry .mdc-image-list__image {
  display: block;
  height: auto;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}
.mdc-layout-grid__cell--order-2 {
  order: 2;
}
.mdc-layout-grid__cell--order-3 {
  order: 3;
}
.mdc-layout-grid__cell--order-4 {
  order: 4;
}
.mdc-layout-grid__cell--order-5 {
  order: 5;
}
.mdc-layout-grid__cell--order-6 {
  order: 6;
}
.mdc-layout-grid__cell--order-7 {
  order: 7;
}
.mdc-layout-grid__cell--order-8 {
  order: 8;
}
.mdc-layout-grid__cell--order-9 {
  order: 9;
}
.mdc-layout-grid__cell--order-10 {
  order: 10;
}
.mdc-layout-grid__cell--order-11 {
  order: 11;
}
.mdc-layout-grid__cell--order-12 {
  order: 12;
}
.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}
.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(
    var(--mdc-layout-grid-column-width-desktop, 72px) * 12 +
      var(--mdc-layout-grid-gutter-desktop, 24px) * 11 +
      var(--mdc-layout-grid-margin-desktop, 24px) * 2
  );
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(
    var(--mdc-layout-grid-column-width-tablet, 72px) * 8 +
      var(--mdc-layout-grid-gutter-tablet, 16px) * 7 +
      var(--mdc-layout-grid-margin-tablet, 16px) * 2
  );
  }
}
@media (max-width: 599px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(
    var(--mdc-layout-grid-column-width-phone, 72px) * 4 +
      var(--mdc-layout-grid-gutter-phone, 16px) * 3 +
      var(--mdc-layout-grid-margin-phone, 16px) * 2
  );
  }
}

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half, 83.67142%));
  }
  100% {
    transform: translateX(200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full, 200.611057%));
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter, 37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half, 84.386165%));
  }
  100% {
    transform: translateX(160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full, 160.277782%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-buffering {
  from {
    transform: rotate(180deg) translateX(-10px);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-half-neg, -83.67142%));
  }
  100% {
    transform: translateX(-200.611057%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-primary-full-neg, -200.611057%));
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-quarter-neg, -37.651913%));
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-half-neg, -84.386165%));
  }
  100% {
    transform: translateX(-160.277782%);
    /* @alternate */
    transform: translateX(var(--mdc-linear-progress-secondary-full-neg, -160.277782%));
  }
}
@keyframes mdc-linear-progress-buffering-reverse {
  from {
    transform: translateX(-10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  transform: translateZ(0);
  outline: 1px solid transparent;
  overflow: hidden;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
@media screen and (forced-colors: active) {
  .mdc-linear-progress {
    outline-color: CanvasText;
  }
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  animation: none;
  border-top-style: solid;
}
.mdc-linear-progress__buffer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mdc-linear-progress__buffer-dots {
  background-repeat: repeat-x;
  flex: auto;
  transform: rotate(180deg);
  animation: mdc-linear-progress-buffering 250ms infinite linear;
}
.mdc-linear-progress__buffer-bar {
  flex: 0 1 100%;
  transition: flex-basis 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}
.mdc-linear-progress__secondary-bar {
  display: none;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  display: block;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}
[dir=rtl] .mdc-linear-progress, .mdc-linear-progress[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__bar {
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: center right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: center right;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__primary-bar {
  animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--animation-ready .mdc-linear-progress__secondary-bar {
  animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]) .mdc-linear-progress__buffer-dots, .mdc-linear-progress[dir=rtl]:not([dir=ltr]) .mdc-linear-progress__buffer-dots {
  animation: mdc-linear-progress-buffering-reverse 250ms infinite linear;
  transform: rotate(0);
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -145.166611%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}
[dir=rtl] .mdc-linear-progress:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar, .mdc-linear-progress[dir=rtl]:not([dir=ltr]).mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  /* @noflip */ /*rtl:ignore*/
  right: -54.888891%;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
}

.mdc-linear-progress--closed {
  opacity: 0;
}
.mdc-linear-progress--closed-animation-off .mdc-linear-progress__buffer-dots {
  animation: none;
}
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar,
.mdc-linear-progress--closed-animation-off.mdc-linear-progress--indeterminate .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
  animation: none;
}

.mdc-linear-progress__bar-inner {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}

.mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6;
}

.mdc-linear-progress {
  height: 4px;
}
.mdc-linear-progress__bar-inner {
  border-top-width: 4px;
}
.mdc-linear-progress__buffer-dots {
  background-size: 10px 4px;
}

.smui-paper {
  padding: 24px 16px;
}
.smui-paper.smui-paper--raised, .smui-paper.smui-paper--unelevated {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--raised.smui-paper--elevation-z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.smui-paper.smui-paper--outlined {
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}
.smui-paper.smui-paper--rounded {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
.smui-paper.smui-paper-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}
.smui-paper .smui-paper__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
  margin-top: 0;
  margin-bottom: 0.4rem;
}
.smui-paper .smui-paper__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin-top: -0.2rem;
  margin-bottom: 0.4rem;
}
.smui-paper .smui-paper__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.smui-paper.smui-paper--color-primary.smui-paper--raised, .smui-paper.smui-paper--color-primary.smui-paper--unelevated {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(255, 62, 0, 0.2), 0px 0px 0px 0px rgba(255, 62, 0, 0.14), 0px 0px 0px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(255, 62, 0, 0.2), 0px 1px 1px 0px rgba(255, 62, 0, 0.14), 0px 1px 3px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(255, 62, 0, 0.2), 0px 2px 2px 0px rgba(255, 62, 0, 0.14), 0px 1px 5px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(255, 62, 0, 0.2), 0px 3px 4px 0px rgba(255, 62, 0, 0.14), 0px 1px 8px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(255, 62, 0, 0.2), 0px 4px 5px 0px rgba(255, 62, 0, 0.14), 0px 1px 10px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(255, 62, 0, 0.2), 0px 5px 8px 0px rgba(255, 62, 0, 0.14), 0px 1px 14px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(255, 62, 0, 0.2), 0px 6px 10px 0px rgba(255, 62, 0, 0.14), 0px 1px 18px 0px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(255, 62, 0, 0.2), 0px 7px 10px 1px rgba(255, 62, 0, 0.14), 0px 2px 16px 1px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(255, 62, 0, 0.2), 0px 8px 10px 1px rgba(255, 62, 0, 0.14), 0px 3px 14px 2px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(255, 62, 0, 0.2), 0px 9px 12px 1px rgba(255, 62, 0, 0.14), 0px 3px 16px 2px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(255, 62, 0, 0.2), 0px 10px 14px 1px rgba(255, 62, 0, 0.14), 0px 4px 18px 3px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(255, 62, 0, 0.2), 0px 11px 15px 1px rgba(255, 62, 0, 0.14), 0px 4px 20px 3px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(255, 62, 0, 0.2), 0px 12px 17px 2px rgba(255, 62, 0, 0.14), 0px 5px 22px 4px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(255, 62, 0, 0.2), 0px 13px 19px 2px rgba(255, 62, 0, 0.14), 0px 5px 24px 4px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(255, 62, 0, 0.2), 0px 14px 21px 2px rgba(255, 62, 0, 0.14), 0px 5px 26px 4px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(255, 62, 0, 0.2), 0px 15px 22px 2px rgba(255, 62, 0, 0.14), 0px 6px 28px 5px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(255, 62, 0, 0.2), 0px 16px 24px 2px rgba(255, 62, 0, 0.14), 0px 6px 30px 5px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(255, 62, 0, 0.2), 0px 17px 26px 2px rgba(255, 62, 0, 0.14), 0px 6px 32px 5px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(255, 62, 0, 0.2), 0px 18px 28px 2px rgba(255, 62, 0, 0.14), 0px 7px 34px 6px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(255, 62, 0, 0.2), 0px 19px 29px 2px rgba(255, 62, 0, 0.14), 0px 7px 36px 6px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(255, 62, 0, 0.2), 0px 20px 31px 3px rgba(255, 62, 0, 0.14), 0px 8px 38px 7px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(255, 62, 0, 0.2), 0px 21px 33px 3px rgba(255, 62, 0, 0.14), 0px 8px 40px 7px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(255, 62, 0, 0.2), 0px 22px 35px 3px rgba(255, 62, 0, 0.14), 0px 8px 42px 7px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(255, 62, 0, 0.2), 0px 23px 36px 3px rgba(255, 62, 0, 0.14), 0px 9px 44px 8px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--raised.smui-paper--elevation-z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(255, 62, 0, 0.2), 0px 24px 38px 3px rgba(255, 62, 0, 0.14), 0px 9px 46px 8px rgba(255, 62, 0, 0.12);
}
.smui-paper.smui-paper--color-primary.smui-paper--outlined {
  border-width: 1px;
  border-style: solid;
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}

.smui-paper.smui-paper--color-secondary.smui-paper--raised, .smui-paper.smui-paper--color-secondary.smui-paper--unelevated {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #676778);
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(103, 103, 120, 0.2), 0px 0px 0px 0px rgba(103, 103, 120, 0.14), 0px 0px 0px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(103, 103, 120, 0.2), 0px 1px 1px 0px rgba(103, 103, 120, 0.14), 0px 1px 3px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(103, 103, 120, 0.2), 0px 2px 2px 0px rgba(103, 103, 120, 0.14), 0px 1px 5px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(103, 103, 120, 0.2), 0px 3px 4px 0px rgba(103, 103, 120, 0.14), 0px 1px 8px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(103, 103, 120, 0.2), 0px 4px 5px 0px rgba(103, 103, 120, 0.14), 0px 1px 10px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(103, 103, 120, 0.2), 0px 5px 8px 0px rgba(103, 103, 120, 0.14), 0px 1px 14px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(103, 103, 120, 0.2), 0px 6px 10px 0px rgba(103, 103, 120, 0.14), 0px 1px 18px 0px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(103, 103, 120, 0.2), 0px 7px 10px 1px rgba(103, 103, 120, 0.14), 0px 2px 16px 1px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(103, 103, 120, 0.2), 0px 8px 10px 1px rgba(103, 103, 120, 0.14), 0px 3px 14px 2px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(103, 103, 120, 0.2), 0px 9px 12px 1px rgba(103, 103, 120, 0.14), 0px 3px 16px 2px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(103, 103, 120, 0.2), 0px 10px 14px 1px rgba(103, 103, 120, 0.14), 0px 4px 18px 3px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(103, 103, 120, 0.2), 0px 11px 15px 1px rgba(103, 103, 120, 0.14), 0px 4px 20px 3px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(103, 103, 120, 0.2), 0px 12px 17px 2px rgba(103, 103, 120, 0.14), 0px 5px 22px 4px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(103, 103, 120, 0.2), 0px 13px 19px 2px rgba(103, 103, 120, 0.14), 0px 5px 24px 4px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(103, 103, 120, 0.2), 0px 14px 21px 2px rgba(103, 103, 120, 0.14), 0px 5px 26px 4px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(103, 103, 120, 0.2), 0px 15px 22px 2px rgba(103, 103, 120, 0.14), 0px 6px 28px 5px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(103, 103, 120, 0.2), 0px 16px 24px 2px rgba(103, 103, 120, 0.14), 0px 6px 30px 5px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(103, 103, 120, 0.2), 0px 17px 26px 2px rgba(103, 103, 120, 0.14), 0px 6px 32px 5px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(103, 103, 120, 0.2), 0px 18px 28px 2px rgba(103, 103, 120, 0.14), 0px 7px 34px 6px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(103, 103, 120, 0.2), 0px 19px 29px 2px rgba(103, 103, 120, 0.14), 0px 7px 36px 6px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(103, 103, 120, 0.2), 0px 20px 31px 3px rgba(103, 103, 120, 0.14), 0px 8px 38px 7px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(103, 103, 120, 0.2), 0px 21px 33px 3px rgba(103, 103, 120, 0.14), 0px 8px 40px 7px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(103, 103, 120, 0.2), 0px 22px 35px 3px rgba(103, 103, 120, 0.14), 0px 8px 42px 7px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(103, 103, 120, 0.2), 0px 23px 36px 3px rgba(103, 103, 120, 0.14), 0px 9px 44px 8px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--raised.smui-paper--elevation-z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(103, 103, 120, 0.2), 0px 24px 38px 3px rgba(103, 103, 120, 0.14), 0px 9px 46px 8px rgba(103, 103, 120, 0.12);
}
.smui-paper.smui-paper--color-secondary.smui-paper--outlined {
  border-width: 1px;
  border-style: solid;
  border-color: #676778;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #676778);
}

.mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #676778;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #676778);
}
.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #676778;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #676778);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-radio .mdc-radio__background::before {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #676778);
}
.mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: GrayText;
  }
  .mdc-radio.mdc-radio--disabled [aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio.mdc-radio--disabled .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: GrayText;
  }
}

.mdc-radio {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color;
}
.mdc-radio__background {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}
.mdc-radio__background::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}
.mdc-radio--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-radio--touch .mdc-radio__native-control {
  top: calc((40px - 48px) / 2);
  right: calc((40px - 48px) / 2);
  left: calc((40px - 48px) / 2);
  width: 48px;
  height: 48px;
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__focus-ring::after, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default;
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-radio .mdc-radio__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-radio .mdc-radio__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-radio.mdc-ripple-upgraded--unbounded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-radio.mdc-ripple-upgraded--foreground-activation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-radio.mdc-ripple-upgraded--foreground-deactivation .mdc-radio__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio .mdc-radio__ripple::before,
.mdc-radio .mdc-radio__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::before,
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio .mdc-radio__ripple::before, .mdc-radio .mdc-radio__ripple::after {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #676778));
}
.mdc-radio:hover .mdc-radio__ripple::before, .mdc-radio.mdc-ripple-surface--hover .mdc-radio__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__ripple::before, .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-radio:not(.mdc-ripple-upgraded) .mdc-radio__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-radio:not(.mdc-ripple-upgraded):active .mdc-radio__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-radio.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-radio.mdc-ripple-upgraded .mdc-radio__background::before, .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
  content: none;
}

.mdc-radio__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-segmented-button {
  display: inline-block;
  font-size: 0;
}

.mdc-segmented-button__segment {
  border-color: rgba(0, 0, 0, 0.12);
  /* @alternate */
  border-color: var(--mdc-segmented-button-outline-color, rgba(0, 0, 0, 0.12));
}

.mdc-segmented-button__segment {
  color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  color: var(--mdc-segmented-button-unselected-ink-color, rgba(0, 0, 0, 0.6));
}

.mdc-segmented-button__segment {
  background-color: white;
  /* @alternate */
  background-color: var(--mdc-segmented-button-unselected-container-fill-color, white);
}

.mdc-segmented-button__segment--selected {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-segmented-button-selected-ink-color, #ff3e00);
}

.mdc-segmented-button__segment--selected {
  background-color: rgba(255, 62, 0, 0.08);
  /* @alternate */
  background-color: var(--mdc-segmented-button-selected-container-fill-color, rgba(255, 62, 0, 0.08));
}

.mdc-segmented-button__segment {
  /* @alternate */
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  height: 36px;
  min-width: 48px;
  padding: 0 12px;
  border-width: 1px 0 1px 1px;
}
.mdc-segmented-button__segment .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-segmented-button__segment:hover {
  cursor: pointer;
}
.mdc-segmented-button__segment:focus {
  outline-width: 0;
}
.mdc-segmented-button__segment:first-child {
  border-radius: 4px 0 0 4px;
}
.mdc-segmented-button__segment:last-child {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}
.mdc-segmented-button__segment .mdc-segmented-button__segment__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.mdc-segmented-button__segment .mdc-segmented-button__segment--touch {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mdc-touch-target-wrapper .mdc-segmented-button__segment {
  border-radius: 0;
  border-right-width: 0;
}
.mdc-touch-target-wrapper:first-child .mdc-segmented-button__segment {
  border-radius: 4px 0 0 4px;
}
.mdc-touch-target-wrapper:last-child .mdc-segmented-button__segment {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}

.mdc-segmented-button__icon {
  width: 24px;
  font-size: 18px;
}

.mdc-segmented-button__icon + .mdc-segmented-button__label {
  padding-left: 6px;
}

.mdc-segmented-button__segment {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  overflow: hidden;
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple::before,
.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-segmented-button__segment.mdc-ripple-upgraded--unbounded .mdc-segmented-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-segmented-button__segment.mdc-ripple-upgraded--foreground-activation .mdc-segmented-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-segmented-button__segment.mdc-ripple-upgraded--foreground-deactivation .mdc-segmented-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple::before,
.mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-segmented-button__segment.mdc-ripple-upgraded .mdc-segmented-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment .mdc-segmented-button__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #ff3e00);
}
.mdc-segmented-button__segment:hover .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment.mdc-ripple-surface--hover .mdc-segmented-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-segmented-button__segment.mdc-ripple-upgraded--background-focused .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment.mdc-ripple-upgraded:focus-within .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment:not(.mdc-ripple-upgraded):focus .mdc-segmented-button__ripple::before, .mdc-segmented-button__segment:not(.mdc-ripple-upgraded):focus-within .mdc-segmented-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-segmented-button__segment:not(.mdc-ripple-upgraded) .mdc-segmented-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-segmented-button__segment:not(.mdc-ripple-upgraded):active .mdc-segmented-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-segmented-button__segment.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-segmented-button__segment .mdc-segmented-button__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-slider__thumb {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-slider__thumb::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-slider__thumb::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-slider__thumb.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-slider__thumb.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-slider__thumb.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-slider__thumb.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-slider__thumb.mdc-ripple-upgraded::before, .mdc-slider__thumb.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-slider__thumb.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-slider__thumb::before, .mdc-slider__thumb::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-slider__thumb:hover::before, .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-slider {
  cursor: pointer;
  height: 48px;
  margin: 0 24px;
  position: relative;
  touch-action: pan-y;
}
.mdc-slider .mdc-slider__track {
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.mdc-slider .mdc-slider__track--active,
.mdc-slider .mdc-slider__track--inactive {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
}
.mdc-slider .mdc-slider__track--active {
  border-radius: 3px;
  height: 6px;
  overflow: hidden;
  top: -1px;
}
.mdc-slider .mdc-slider__track--active_fill {
  border-top: 6px solid;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: left;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: left;
}
[dir=rtl] .mdc-slider .mdc-slider__track--active_fill, .mdc-slider .mdc-slider__track--active_fill[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  -webkit-transform-origin: right;
  /* @noflip */ /*rtl:ignore*/
  transform-origin: right;
  /*rtl:end:ignore*/
}

.mdc-slider .mdc-slider__track--inactive {
  border-radius: 2px;
  height: 4px;
  left: 0;
  top: 0;
}
.mdc-slider .mdc-slider__track--inactive::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-slider .mdc-slider__track--inactive::before {
    border-color: CanvasText;
  }
}
.mdc-slider .mdc-slider__track--active_fill {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-slider.mdc-slider--disabled .mdc-slider__track--active_fill {
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}
.mdc-slider .mdc-slider__track--inactive {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  opacity: 0.24;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.24;
}
.mdc-slider .mdc-slider__value-indicator-container {
  bottom: 44px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  left: var(--slider-value-indicator-container-left, 50%);
  pointer-events: none;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: var(--slider-value-indicator-container-right);
  transform: translateX(-50%);
  /* @alternate */
  transform: var(--slider-value-indicator-container-transform, translateX(-50%));
}
.mdc-slider .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0.4, 0, 1, 1);
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 12px;
  transform: scale(0);
  transform-origin: bottom;
}
.mdc-slider .mdc-slider__value-indicator::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  bottom: -5px;
  content: "";
  height: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  left: var(--slider-value-indicator-caret-left, 50%);
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: var(--slider-value-indicator-caret-right);
  transform: translateX(-50%);
  /* @alternate */
  transform: var(--slider-value-indicator-caret-transform, translateX(-50%));
  width: 0;
}
.mdc-slider .mdc-slider__value-indicator::after {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-slider .mdc-slider__value-indicator::after {
    border-color: CanvasText;
  }
}
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator-container {
  pointer-events: auto;
}
.mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
  transition: transform 100ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(1);
}
@media (prefers-reduced-motion) {
  .mdc-slider .mdc-slider__value-indicator,
  .mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transition: none;
  }
}
.mdc-slider .mdc-slider__value-indicator-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}
.mdc-slider .mdc-slider__value-indicator {
  background-color: #000;
  opacity: 0.6;
}
.mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: #000;
}
.mdc-slider .mdc-slider__value-indicator {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-slider .mdc-slider__thumb {
  display: flex;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: -24px;
  outline: none;
  position: absolute;
  user-select: none;
  width: 48px;
}
.mdc-slider .mdc-slider__thumb--top {
  z-index: 1;
}
.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-style: solid;
  border-width: 1px;
  box-sizing: content-box;
}
.mdc-slider .mdc-slider__thumb-knob {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border: 10px solid;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}
.mdc-slider .mdc-slider__thumb-knob {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-slider .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-color: #fff;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  border-color: #000;
  /* @alternate */
  border-color: var(--mdc-theme-on-surface, #000);
}
.mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top .mdc-slider__thumb-knob, .mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top.mdc-slider__thumb:hover .mdc-slider__thumb-knob, .mdc-slider.mdc-slider--disabled .mdc-slider__thumb--top.mdc-slider__thumb--focused .mdc-slider__thumb-knob {
  border-color: #fff;
}
.mdc-slider .mdc-slider__thumb::before, .mdc-slider .mdc-slider__thumb::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-slider .mdc-slider__thumb:hover::before, .mdc-slider .mdc-slider__thumb.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded--background-focused::before, .mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-slider .mdc-slider__thumb:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider .mdc-slider__thumb.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-slider .mdc-slider__tick-marks {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 1px;
  position: absolute;
  width: 100%;
}
.mdc-slider .mdc-slider__tick-mark--active,
.mdc-slider .mdc-slider__tick-mark--inactive {
  border-radius: 50%;
  height: 2px;
  width: 2px;
}
.mdc-slider .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--active {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, #fff);
  opacity: 0.6;
}
.mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  opacity: 0.6;
}
.mdc-slider.mdc-slider--disabled .mdc-slider__tick-mark--inactive {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
  opacity: 0.6;
}
.mdc-slider--discrete .mdc-slider__thumb,
.mdc-slider--discrete .mdc-slider__track--active_fill {
  transition: transform 80ms ease;
}
@media (prefers-reduced-motion) {
  .mdc-slider--discrete .mdc-slider__thumb,
  .mdc-slider--discrete .mdc-slider__track--active_fill {
    transition: none;
  }
}

.mdc-slider--disabled {
  opacity: 0.38;
  cursor: auto;
}
.mdc-slider--disabled .mdc-slider__thumb {
  pointer-events: none;
}

.mdc-slider__input {
  cursor: pointer;
  left: 0;
  margin: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.mdc-slider {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}

.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--open .mdc-snackbar__label,
.mdc-snackbar--open .mdc-snackbar__actions {
  visibility: visible;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  padding-bottom: 12px;
}
[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}
.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-snackbar__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-snackbar__surface::before {
    border-color: CanvasText;
  }
}
[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}
[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  visibility: hidden;
}

.mdc-snackbar__action:not(:disabled) {
  color: #ff3e00;
}
.mdc-snackbar__action .mdc-button__ripple::before, .mdc-snackbar__action .mdc-button__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #ff3e00);
}
.mdc-snackbar__action:hover .mdc-button__ripple::before, .mdc-snackbar__action.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-snackbar__action.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}
.mdc-snackbar__dismiss .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss .mdc-icon-button__ripple::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.mdc-snackbar__dismiss:hover .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 6px;
  font-size: 18px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__focus-ring {
  display: none;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  display: block;
  max-height: 36px;
  max-width: 36px;
}
@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    /* @noflip */ /*rtl:ignore*/
    left: 50%;
    /* @noflip */ /*rtl:ignore*/
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}
@media screen and (forced-colors: active) and (forced-colors: active) {
  .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring::after, .mdc-snackbar__dismiss.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-snackbar__dismiss.mdc-snackbar__dismiss.mdc-icon-button--reduced-size:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-switch {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
}
.mdc-switch:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-switch__track {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.mdc-switch__track::before, .mdc-switch__track::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  width: 100%;
}
@media screen and (forced-colors: active) {
  .mdc-switch__track::before, .mdc-switch__track::after {
    border-color: currentColor;
  }
}
.mdc-switch__track::before {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}
.mdc-switch__track::after {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(-100%);
}
[dir=rtl] .mdc-switch__track::after, .mdc-switch__track[dir=rtl]::after {
  /*rtl:begin:ignore*/
  transform: translateX(100%);
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__track::before {
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  transform: translateX(100%);
}
[dir=rtl] .mdc-switch--selected .mdc-switch__track::before, .mdc-switch--selected .mdc-switch__track[dir=rtl]::before {
  /*rtl:begin:ignore*/
  transform: translateX(-100%);
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__track::after {
  transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateX(0);
}

.mdc-switch__handle-track {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
  transform: translateX(0);
}
[dir=rtl] .mdc-switch__handle-track, .mdc-switch__handle-track[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(100%);
}
[dir=rtl] .mdc-switch--selected .mdc-switch__handle-track, .mdc-switch--selected .mdc-switch__handle-track[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(-100%);
  /*rtl:end:ignore*/
}

.mdc-switch__handle {
  display: flex;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
}
[dir=rtl] .mdc-switch__handle, .mdc-switch__handle[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.mdc-switch__handle::before, .mdc-switch__handle::after {
  border: 1px solid transparent;
  border-radius: inherit;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1), border-color 75ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
@media screen and (forced-colors: active) {
  .mdc-switch__handle::before, .mdc-switch__handle::after {
    border-color: currentColor;
  }
}

.mdc-switch__shadow {
  border-radius: inherit;
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
}

.mdc-elevation-overlay {
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
}

.mdc-switch__ripple {
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.mdc-switch:disabled .mdc-switch__ripple {
  display: none;
}

.mdc-switch__icons {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

.mdc-switch__icon {
  bottom: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  margin: auto;
  position: absolute;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  top: 0;
  opacity: 0;
  transition: opacity 30ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-switch--selected .mdc-switch__icon--on,
.mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 1;
  transition: opacity 45ms 30ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-switch {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-switch .mdc-switch__ripple::before,
.mdc-switch .mdc-switch__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-switch .mdc-switch__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-switch .mdc-switch__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-switch.mdc-ripple-upgraded--unbounded .mdc-switch__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch.mdc-ripple-upgraded--foreground-activation .mdc-switch__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch.mdc-ripple-upgraded--foreground-deactivation .mdc-switch__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch .mdc-switch__ripple::before,
.mdc-switch .mdc-switch__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::before,
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch.mdc-ripple-upgraded .mdc-switch__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch .mdc-switch__focus-ring-wrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
}
.mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + 4px
    );
  width: calc(
      100% + 4px
    );
}
@media screen and (forced-colors: active) {
  .mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring::after, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-switch.mdc-ripple-upgraded--background-focused .mdc-switch__focus-ring::after, .mdc-switch:not(.mdc-ripple-upgraded):focus .mdc-switch__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-switch {
  width: 36px;
  /* @alternate */
  width: var(--mdc-switch-track-width, 36px);
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #ff3e00;
  /* @alternate */
  background: var(--mdc-switch-selected-handle-color, var(--mdc-theme-primary, #ff3e00));
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background: #801f00;
  /* @alternate */
  background: var(--mdc-switch-selected-hover-handle-color, #801f00);
}

.mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #801f00;
  /* @alternate */
  background: var(--mdc-switch-selected-focus-handle-color, #801f00);
}

.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after {
  background: #801f00;
  /* @alternate */
  background: var(--mdc-switch-selected-pressed-handle-color, #801f00);
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-selected-handle-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #616161;
  /* @alternate */
  background: var(--mdc-switch-unselected-handle-color, #616161);
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-hover-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-focus-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after {
  background: #212121;
  /* @alternate */
  background: var(--mdc-switch-unselected-pressed-handle-color, #212121);
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-unselected-handle-color, #424242);
}

.mdc-switch .mdc-switch__handle::before {
  background: #fff;
  /* @alternate */
  background: var(--mdc-switch-handle-surface-color, var(--mdc-theme-surface, #fff));
}
.mdc-switch:enabled .mdc-switch__shadow {
  --mdc-elevation-box-shadow-for-gss: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-switch-handle-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-switch:disabled .mdc-switch__shadow {
  --mdc-elevation-box-shadow-for-gss: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  /* @alternate */
  box-shadow: var(--mdc-switch-disabled-handle-elevation, var(--mdc-elevation-box-shadow-for-gss));
}
.mdc-switch .mdc-switch__focus-ring-wrapper,
.mdc-switch .mdc-switch__handle {
  height: 20px;
  /* @alternate */
  height: var(--mdc-switch-handle-height, 20px);
}
.mdc-switch:disabled .mdc-switch__handle::after {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-handle-opacity, 0.38);
}

.mdc-switch .mdc-switch__handle {
  border-radius: 10px;
  /* @alternate */
  border-radius: var(--mdc-switch-handle-shape, 10px);
}
.mdc-switch .mdc-switch__handle {
  width: 20px;
  /* @alternate */
  width: var(--mdc-switch-handle-width, 20px);
}
.mdc-switch .mdc-switch__handle-track {
  width: calc(100% - 20px);
  /* @alternate */
  width: calc(100% - var(--mdc-switch-handle-width, 20px));
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-selected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-disabled-selected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-unselected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
  fill: #fff;
  /* @alternate */
  fill: var(--mdc-switch-disabled-unselected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-selected-icon-opacity, 0.38);
}

.mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icons {
  opacity: 0.38;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-unselected-icon-opacity, 0.38);
}

.mdc-switch.mdc-switch--selected .mdc-switch__icon {
  width: 18px;
  /* @alternate */
  width: var(--mdc-switch-selected-icon-size, 18px);
  height: 18px;
  /* @alternate */
  height: var(--mdc-switch-selected-icon-size, 18px);
}

.mdc-switch.mdc-switch--unselected .mdc-switch__icon {
  width: 18px;
  /* @alternate */
  width: var(--mdc-switch-unselected-icon-size, 18px);
  height: 18px;
  /* @alternate */
  height: var(--mdc-switch-unselected-icon-size, 18px);
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-switch-selected-hover-state-layer-color, var(--mdc-theme-primary, #ff3e00));
}

.mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-switch-selected-focus-state-layer-color, var(--mdc-theme-primary, #ff3e00));
}

.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-switch-selected-pressed-state-layer-color, var(--mdc-theme-primary, #ff3e00));
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-hover-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-focus-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__ripple::before,
.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__ripple::after {
  background-color: #424242;
  /* @alternate */
  background-color: var(--mdc-switch-unselected-pressed-state-layer-color, #424242);
}

.mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):hover .mdc-switch__ripple::before, .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus).mdc-ripple-surface--hover .mdc-switch__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-switch-selected-hover-state-layer-opacity, 0.04);
}

.mdc-switch.mdc-switch--selected:enabled:focus.mdc-ripple-upgraded--background-focused .mdc-switch__ripple::before, .mdc-switch.mdc-switch--selected:enabled:focus:not(.mdc-ripple-upgraded):focus .mdc-switch__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-selected-focus-state-layer-opacity, 0.12);
}

.mdc-switch.mdc-switch--selected:enabled:active:not(.mdc-ripple-upgraded) .mdc-switch__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-switch.mdc-switch--selected:enabled:active:not(.mdc-ripple-upgraded):active .mdc-switch__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-switch-selected-pressed-state-layer-opacity, 0.1);
}
.mdc-switch.mdc-switch--selected:enabled:active.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-switch-selected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):hover .mdc-switch__ripple::before, .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus).mdc-ripple-surface--hover .mdc-switch__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-hover-state-layer-opacity, 0.04);
}

.mdc-switch.mdc-switch--unselected:enabled:focus.mdc-ripple-upgraded--background-focused .mdc-switch__ripple::before, .mdc-switch.mdc-switch--unselected:enabled:focus:not(.mdc-ripple-upgraded):focus .mdc-switch__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-focus-state-layer-opacity, 0.12);
}

.mdc-switch.mdc-switch--unselected:enabled:active:not(.mdc-ripple-upgraded) .mdc-switch__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-switch.mdc-switch--unselected:enabled:active:not(.mdc-ripple-upgraded):active .mdc-switch__ripple::after {
  transition-duration: 75ms;
  opacity: 0.1;
  /* @alternate */
  opacity: var(--mdc-switch-unselected-pressed-state-layer-opacity, 0.1);
}
.mdc-switch.mdc-switch--unselected:enabled:active.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-switch-unselected-pressed-state-layer-opacity, 0.1);
}

.mdc-switch .mdc-switch__ripple {
  height: 48px;
  /* @alternate */
  height: var(--mdc-switch-state-layer-size, 48px);
  width: 48px;
  /* @alternate */
  width: var(--mdc-switch-state-layer-size, 48px);
}
.mdc-switch .mdc-switch__track {
  height: 14px;
  /* @alternate */
  height: var(--mdc-switch-track-height, 14px);
}
.mdc-switch:disabled .mdc-switch__track {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-switch-disabled-track-opacity, 0.12);
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #ffcfbf;
  /* @alternate */
  background: var(--mdc-switch-selected-track-color, #ffcfbf);
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
  background: #ffcfbf;
  /* @alternate */
  background: var(--mdc-switch-selected-hover-track-color, #ffcfbf);
}

.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background: #ffcfbf;
  /* @alternate */
  background: var(--mdc-switch-selected-focus-track-color, #ffcfbf);
}

.mdc-switch:enabled:active .mdc-switch__track::after {
  background: #ffcfbf;
  /* @alternate */
  background: var(--mdc-switch-selected-pressed-track-color, #ffcfbf);
}

.mdc-switch:disabled .mdc-switch__track::after {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-selected-track-color, #424242);
}

.mdc-switch:enabled .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-track-color, #e0e0e0);
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-hover-track-color, #e0e0e0);
}

.mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-focus-track-color, #e0e0e0);
}

.mdc-switch:enabled:active .mdc-switch__track::before {
  background: #e0e0e0;
  /* @alternate */
  background: var(--mdc-switch-unselected-pressed-track-color, #e0e0e0);
}

.mdc-switch:disabled .mdc-switch__track::before {
  background: #424242;
  /* @alternate */
  background: var(--mdc-switch-disabled-unselected-track-color, #424242);
}

.mdc-switch .mdc-switch__track {
  border-radius: 7px;
  /* @alternate */
  border-radius: var(--mdc-switch-track-shape, 7px);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-switch:enabled .mdc-switch__shadow {
    /* @alternate */
  }
  .mdc-switch:disabled .mdc-switch__shadow {
    /* @alternate */
  }
  .mdc-switch:disabled .mdc-switch__handle::after {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-handle-opacity, 1);
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: ButtonText;
    /* @alternate */
    fill: var(--mdc-switch-selected-icon-color, ButtonText);
  }
  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
    fill: GrayText;
    /* @alternate */
    fill: var(--mdc-switch-disabled-selected-icon-color, GrayText);
  }
  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    fill: ButtonText;
    /* @alternate */
    fill: var(--mdc-switch-unselected-icon-color, ButtonText);
  }
  .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
    fill: GrayText;
    /* @alternate */
    fill: var(--mdc-switch-disabled-unselected-icon-color, GrayText);
  }
  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-selected-icon-opacity, 1);
  }
  .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icons {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-unselected-icon-opacity, 1);
  }
  .mdc-switch:disabled .mdc-switch__track {
    opacity: 1;
    /* @alternate */
    opacity: var(--mdc-switch-disabled-track-opacity, 1);
  }
}
.mdc-switch {
  margin: 14px;
}

.mdc-switch.smui-switch--color-secondary {
  --mdc-switch-disabled-selected-icon-color: var(--mdc-theme-on-secondary, #fff);
  --mdc-switch-disabled-unselected-icon-color: var(--mdc-theme-on-secondary, #fff);
  --mdc-switch-selected-focus-handle-color: #585858;
  --mdc-switch-selected-focus-state-layer-color: var(--mdc-theme-secondary, #676778);
  --mdc-switch-selected-focus-track-color: #d8d8de;
  --mdc-switch-selected-handle-color: var(--mdc-theme-secondary, #676778);
  --mdc-switch-selected-hover-handle-color: #585858;
  --mdc-switch-selected-hover-state-layer-color: var(--mdc-theme-secondary, #676778);
  --mdc-switch-selected-hover-track-color: #d8d8de;
  --mdc-switch-selected-icon-color: var(--mdc-theme-on-secondary, #fff);
  --mdc-switch-selected-pressed-handle-color: #585858;
  --mdc-switch-selected-pressed-state-layer-color: var(--mdc-theme-secondary, #676778);
  --mdc-switch-selected-pressed-track-color: #d8d8de;
  --mdc-switch-selected-track-color: #d8d8de;
  --mdc-switch-unselected-icon-color: var(--mdc-theme-on-secondary, #fff);
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  position: relative;
}
.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -12px
    );
  width: calc(
      100% + -8px
    );
  margin-top: -2px;
  z-index: 2;
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -8px
    );
  width: calc(
      100% + -8px
    );
  z-index: 2;
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  /* @noflip */ /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-tab__content {
  position: relative;
}

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-tab--active .mdc-tab__icon {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
  fill: currentColor;
}

.mdc-tab {
  background: none;
}

.mdc-tab {
  min-width: 90px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}
.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #ff3e00));
}
.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #676778;
  /* @alternate */
  color: var(--mdc-theme-secondary, #676778);
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}
.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

.mdc-text-field--filled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field--filled .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-text-field--filled .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #ff3e00;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
.mdc-text-field__input::-ms-clear {
  display: none;
}
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: none;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}

.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}
.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--outlined .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled {
  height: 56px;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #ff3e00;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-text-field--filled .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 16px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--filled .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}
.mdc-text-field--filled.mdc-text-field--no-label::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #ff3e00;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff3e00);
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
  /*rtl:end:ignore*/
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-leading-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, var(--mdc-shape-small, 4px));
  }
}

.mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
    /* @noflip */ /*rtl:ignore*/
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /*rtl:end:ignore*/
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
    /* @noflip */ /*rtl:ignore*/
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}

.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: transparent;
  /* @alternate */
  background-color: var(--mdc-ripple-color, transparent);
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 4px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 4px;
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 1;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}
.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}
.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}
.mdc-text-field--textarea.mdc-text-field--filled::before {
  display: none;
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}
.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}
.mdc-text-field--filled .mdc-text-field__resizer {
  transform: translateY(-1px);
}
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(-1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(-1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(1px) translateY(1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, [dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl], .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateX(-1px) translateY(1px);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon, .mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */ /*rtl:ignore*/
  left: 48px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 48px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 36px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 36px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-37.25px) translateX(32px) scale(1);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, [dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl], .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    /* @noflip */ /*rtl:ignore*/
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}

.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--with-trailing-icon, .mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 62, 0, 0.87);
}
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}
.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b71c1c;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b71c1c;
  /* @alternate */
  color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b71c1c;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b71c1c;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b71c1c);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
@media screen and (forced-colors: active) {
  .mdc-text-field--disabled .mdc-text-field__input {
    background-color: Window;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    z-index: 1;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple {
  display: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--ltr-text, .mdc-text-field--ltr-text[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */ /*rtl:ignore*/
  direction: ltr;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}

[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.smui-text-field--standard {
  height: 56px;
  padding: 0;
}
.smui-text-field--standard::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.smui-text-field--standard:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.smui-text-field--standard:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.smui-text-field--standard:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.smui-text-field--standard .mdc-line-ripple::after {
  border-bottom-color: #ff3e00;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #ff3e00);
}
.smui-text-field--standard .mdc-floating-label {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-text-field--standard .mdc-floating-label, .smui-text-field--standard .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.smui-text-field--standard .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.smui-text-field--standard.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}
.smui-text-field--standard.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}
.smui-text-field--standard.mdc-text-field--no-label::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .smui-text-field--standard.mdc-text-field--no-label .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field--with-leading-icon.smui-text-field--standard .mdc-floating-label {
  max-width: calc(100% - 32px);
  /* @noflip */ /*rtl:ignore*/
  left: 32px;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.smui-text-field--standard .mdc-floating-label, .mdc-text-field--with-leading-icon.smui-text-field--standard .mdc-floating-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 32px;
  /*rtl:end:ignore*/
}

.mdc-text-field--with-leading-icon.smui-text-field--standard .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 48px / 0.75);
}

.mdc-text-field--with-trailing-icon.smui-text-field--standard .mdc-floating-label {
  max-width: calc(100% - 36px);
}
.mdc-text-field--with-trailing-icon.smui-text-field--standard .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 36px / 0.75);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.smui-text-field--standard .mdc-floating-label {
  max-width: calc(100% - 68px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.smui-text-field--standard .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 68px / 0.75);
}

.mdc-text-field + .mdc-text-field-helper-line {
  padding-left: 0;
  padding-right: 0;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */ /*rtl:ignore*/
  margin-left: auto;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: auto;
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 16px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 16px;
  /*rtl:end:ignore*/
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.smui-text-field--standard .mdc-text-field__icon--leading {
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 8px;
}
[dir=rtl] .smui-text-field--standard .mdc-text-field__icon--leading, .smui-text-field--standard .mdc-text-field__icon--leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 8px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
  /*rtl:end:ignore*/
}

.smui-text-field--standard .mdc-text-field__icon--trailing {
  padding-top: 12px;
  padding-bottom: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0px;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0;
}
[dir=rtl] .smui-text-field--standard .mdc-text-field__icon--trailing, .smui-text-field--standard .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

[dir=rtl] .smui-text-field--standard .mdc-text-field__icon--trailing, .smui-text-field--standard .mdc-text-field__icon--trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  margin-left: 0;
  /* @noflip */ /*rtl:ignore*/
  margin-right: 0px;
  /*rtl:end:ignore*/
}

.mdc-tooltip__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__caret-surface-top,
.mdc-tooltip__caret-surface-bottom {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-tooltip__surface {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}

.mdc-tooltip__surface {
  background-color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__surface {
  word-break: break-all;
  /* @alternate */
  word-break: var(--mdc-tooltip-word-break, normal);
  overflow-wrap: anywhere;
}

.mdc-tooltip {
  z-index: 9;
}

.mdc-tooltip--showing-transition .mdc-tooltip__surface-animation {
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-tooltip--hide-transition .mdc-tooltip__surface-animation {
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-tooltip__title {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
}

.mdc-tooltip__content {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tooltip__content-link {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}

.mdc-tooltip {
  position: fixed;
  display: none;
}
.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__surface {
  background-color: #fff;
}
.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-top,
.mdc-tooltip.mdc-tooltip--rich .mdc-tooltip__caret-surface-bottom {
  background-color: #fff;
}

.mdc-tooltip-wrapper--rich {
  position: relative;
}

.mdc-tooltip--shown,
.mdc-tooltip--showing,
.mdc-tooltip--hide {
  display: inline-flex;
}
.mdc-tooltip--shown.mdc-tooltip--rich,
.mdc-tooltip--showing.mdc-tooltip--rich,
.mdc-tooltip--hide.mdc-tooltip--rich {
  display: inline-block;
  /* @noflip */ /*rtl:ignore*/
  left: -320px;
  position: absolute;
}

.mdc-tooltip__surface {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  line-height: 16px;
  padding: 4px 8px;
  min-width: 40px;
  max-width: 200px;
  min-height: 24px;
  max-height: 40vh;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
}
.mdc-tooltip__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
  pointer-events: none;
}
@media screen and (forced-colors: active) {
  .mdc-tooltip__surface::before {
    border-color: CanvasText;
  }
}
.mdc-tooltip--rich .mdc-tooltip__surface {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  min-height: 24px;
  min-width: 40px;
  max-width: 320px;
  position: relative;
}
.mdc-tooltip--rich .mdc-tooltip__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}
.mdc-tooltip--multiline .mdc-tooltip__surface {
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-tooltip--multiline .mdc-tooltip__surface, .mdc-tooltip--multiline .mdc-tooltip__surface[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-tooltip__surface .mdc-tooltip__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  margin: 0 8px;
}
.mdc-tooltip__surface .mdc-tooltip__title::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-tooltip__surface .mdc-tooltip__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  max-width: calc(200px - (2 * 8px));
  margin: 8px;
  /* @noflip */ /*rtl:ignore*/
  text-align: left;
}
[dir=rtl] .mdc-tooltip__surface .mdc-tooltip__content, .mdc-tooltip__surface .mdc-tooltip__content[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-tooltip--rich .mdc-tooltip__surface .mdc-tooltip__content {
  max-width: calc(320px - (2 * 8px));
  align-self: stretch;
}
.mdc-tooltip__surface .mdc-tooltip__content-link {
  text-decoration: none;
}

.mdc-tooltip--rich-actions,
.mdc-tooltip__content,
.mdc-tooltip__title {
  z-index: 1;
}

.mdc-tooltip__surface-animation {
  opacity: 0;
  transform: scale(0.8);
  will-change: transform, opacity;
}
.mdc-tooltip--shown .mdc-tooltip__surface-animation {
  transform: scale(1);
  opacity: 1;
}
.mdc-tooltip--hide .mdc-tooltip__surface-animation {
  transform: scale(1);
}

.mdc-tooltip__caret-surface-top,
.mdc-tooltip__caret-surface-bottom {
  position: absolute;
  height: 24px;
  width: 24px;
  transform: rotate(35deg) skewY(20deg) scaleX(0.9396926208);
}
.mdc-tooltip__caret-surface-top .mdc-elevation-overlay,
.mdc-tooltip__caret-surface-bottom .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
}

.mdc-tooltip__caret-surface-bottom {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  outline: 1px solid transparent;
  z-index: -1;
}
@media screen and (forced-colors: active) {
  .mdc-tooltip__caret-surface-bottom {
    outline-color: CanvasText;
  }
}

.mdc-tooltip--rich .mdc-tooltip__title {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-tooltip--rich .mdc-tooltip__content {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-tooltip--rich .mdc-tooltip__content-link {
  color: #ff3e00;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff3e00);
}

.smui-tooltip--force-show {
  display: inline-flex !important;
}
.smui-tooltip--force-show.mdc-tooltip--rich {
  display: inline-block !important;
}

.mdc-top-app-bar {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 24px;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  border-top-left-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-top-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-right-radius: 0;
  /* @noflip */ /*rtl:ignore*/
  border-bottom-left-radius: 24px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  right: auto;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /* @noflip */ /*rtl:ignore*/
  left: auto;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 12px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 12px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */ /*rtl:ignore*/
  padding-left: 20px;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */ /*rtl:ignore*/
  padding-right: 20px;
  /*rtl:end:ignore*/
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }
  .mdc-top-app-bar__section {
    padding: 4px;
  }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */ /*rtl:ignore*/
    padding-left: 0;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /*rtl:begin:ignore*/
    /* @noflip */ /*rtl:ignore*/
    padding-left: 12px;
    /* @noflip */ /*rtl:ignore*/
    padding-right: 0;
    /*rtl:end:ignore*/
  }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.smui-top-app-bar--static {
  position: static;
}

.smui-top-app-bar--color-secondary {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #676778);
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item::before, .smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item::after,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon::before,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-secondary, #fff));
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item:hover::before, .smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon:hover::before,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.smui-top-app-bar--color-secondary .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.smui-top-app-bar--color-secondary .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.smui-accordion {
  display: block;
  position: relative;
  z-index: 0;
}
.smui-accordion.smui-accordion--with-open-dialog {
  z-index: auto;
}
.smui-accordion .smui-accordion__panel {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition: margin-top 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1), margin-bottom 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::before,
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded .smui-accordion__header__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded .smui-accordion__header__ripple::after {
  top: 0;
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded--unbounded .smui-accordion__header__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */ /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded--foreground-activation .smui-accordion__header__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded--foreground-deactivation .smui-accordion__header__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::before,
.smui-accordion .smui-accordion__panel .smui-accordion__header__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */ /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.smui-accordion .smui-accordion__panel.mdc-ripple-upgraded .smui-accordion__header__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--extend {
  transition: width 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1), left 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1), margin-top 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1), margin-bottom 0.2s 0ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  left: 0;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--extend.smui-accordion__panel--open {
  width: calc(100% + 10px);
  left: -5px;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised, .smui-accordion .smui-accordion__panel.smui-paper--unelevated {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-paper.smui-accordion__panel--raised {
  z-index: auto;
  will-change: auto;
}
.smui-accordion .smui-accordion__panel.smui-paper.smui-accordion__panel--raised::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z0::before {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z1::before {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z2::before {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z3::before {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z4::before {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z5::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z6::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z7::before {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z8::before {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z9::before {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z10::before {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z11::before {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z12::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z13::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z14::before {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z15::before {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z16::before {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z17::before {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z18::before {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z19::before {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z20::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z21::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z22::before {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z23::before {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--elevation-z24::before {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z0::before {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(255, 62, 0, 0.2), 0px 0px 0px 0px rgba(255, 62, 0, 0.14), 0px 0px 0px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z1::before {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(255, 62, 0, 0.2), 0px 1px 1px 0px rgba(255, 62, 0, 0.14), 0px 1px 3px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z2::before {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(255, 62, 0, 0.2), 0px 2px 2px 0px rgba(255, 62, 0, 0.14), 0px 1px 5px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z3::before {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(255, 62, 0, 0.2), 0px 3px 4px 0px rgba(255, 62, 0, 0.14), 0px 1px 8px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z4::before {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(255, 62, 0, 0.2), 0px 4px 5px 0px rgba(255, 62, 0, 0.14), 0px 1px 10px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z5::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(255, 62, 0, 0.2), 0px 5px 8px 0px rgba(255, 62, 0, 0.14), 0px 1px 14px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z6::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(255, 62, 0, 0.2), 0px 6px 10px 0px rgba(255, 62, 0, 0.14), 0px 1px 18px 0px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z7::before {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(255, 62, 0, 0.2), 0px 7px 10px 1px rgba(255, 62, 0, 0.14), 0px 2px 16px 1px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z8::before {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(255, 62, 0, 0.2), 0px 8px 10px 1px rgba(255, 62, 0, 0.14), 0px 3px 14px 2px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z9::before {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(255, 62, 0, 0.2), 0px 9px 12px 1px rgba(255, 62, 0, 0.14), 0px 3px 16px 2px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z10::before {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(255, 62, 0, 0.2), 0px 10px 14px 1px rgba(255, 62, 0, 0.14), 0px 4px 18px 3px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z11::before {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(255, 62, 0, 0.2), 0px 11px 15px 1px rgba(255, 62, 0, 0.14), 0px 4px 20px 3px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z12::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(255, 62, 0, 0.2), 0px 12px 17px 2px rgba(255, 62, 0, 0.14), 0px 5px 22px 4px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z13::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(255, 62, 0, 0.2), 0px 13px 19px 2px rgba(255, 62, 0, 0.14), 0px 5px 24px 4px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z14::before {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(255, 62, 0, 0.2), 0px 14px 21px 2px rgba(255, 62, 0, 0.14), 0px 5px 26px 4px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z15::before {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(255, 62, 0, 0.2), 0px 15px 22px 2px rgba(255, 62, 0, 0.14), 0px 6px 28px 5px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z16::before {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(255, 62, 0, 0.2), 0px 16px 24px 2px rgba(255, 62, 0, 0.14), 0px 6px 30px 5px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z17::before {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(255, 62, 0, 0.2), 0px 17px 26px 2px rgba(255, 62, 0, 0.14), 0px 6px 32px 5px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z18::before {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(255, 62, 0, 0.2), 0px 18px 28px 2px rgba(255, 62, 0, 0.14), 0px 7px 34px 6px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z19::before {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(255, 62, 0, 0.2), 0px 19px 29px 2px rgba(255, 62, 0, 0.14), 0px 7px 36px 6px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z20::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(255, 62, 0, 0.2), 0px 20px 31px 3px rgba(255, 62, 0, 0.14), 0px 8px 38px 7px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z21::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(255, 62, 0, 0.2), 0px 21px 33px 3px rgba(255, 62, 0, 0.14), 0px 8px 40px 7px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z22::before {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(255, 62, 0, 0.2), 0px 22px 35px 3px rgba(255, 62, 0, 0.14), 0px 8px 42px 7px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z23::before {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(255, 62, 0, 0.2), 0px 23px 36px 3px rgba(255, 62, 0, 0.14), 0px 9px 44px 8px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-primary.smui-accordion__panel--elevation-z24::before {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(255, 62, 0, 0.2), 0px 24px 38px 3px rgba(255, 62, 0, 0.14), 0px 9px 46px 8px rgba(255, 62, 0, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z0::before {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(103, 103, 120, 0.2), 0px 0px 0px 0px rgba(103, 103, 120, 0.14), 0px 0px 0px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z1::before {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(103, 103, 120, 0.2), 0px 1px 1px 0px rgba(103, 103, 120, 0.14), 0px 1px 3px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z2::before {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(103, 103, 120, 0.2), 0px 2px 2px 0px rgba(103, 103, 120, 0.14), 0px 1px 5px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z3::before {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(103, 103, 120, 0.2), 0px 3px 4px 0px rgba(103, 103, 120, 0.14), 0px 1px 8px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z4::before {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(103, 103, 120, 0.2), 0px 4px 5px 0px rgba(103, 103, 120, 0.14), 0px 1px 10px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z5::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(103, 103, 120, 0.2), 0px 5px 8px 0px rgba(103, 103, 120, 0.14), 0px 1px 14px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z6::before {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(103, 103, 120, 0.2), 0px 6px 10px 0px rgba(103, 103, 120, 0.14), 0px 1px 18px 0px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z7::before {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(103, 103, 120, 0.2), 0px 7px 10px 1px rgba(103, 103, 120, 0.14), 0px 2px 16px 1px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z8::before {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(103, 103, 120, 0.2), 0px 8px 10px 1px rgba(103, 103, 120, 0.14), 0px 3px 14px 2px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z9::before {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(103, 103, 120, 0.2), 0px 9px 12px 1px rgba(103, 103, 120, 0.14), 0px 3px 16px 2px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z10::before {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(103, 103, 120, 0.2), 0px 10px 14px 1px rgba(103, 103, 120, 0.14), 0px 4px 18px 3px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z11::before {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(103, 103, 120, 0.2), 0px 11px 15px 1px rgba(103, 103, 120, 0.14), 0px 4px 20px 3px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z12::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(103, 103, 120, 0.2), 0px 12px 17px 2px rgba(103, 103, 120, 0.14), 0px 5px 22px 4px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z13::before {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(103, 103, 120, 0.2), 0px 13px 19px 2px rgba(103, 103, 120, 0.14), 0px 5px 24px 4px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z14::before {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(103, 103, 120, 0.2), 0px 14px 21px 2px rgba(103, 103, 120, 0.14), 0px 5px 26px 4px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z15::before {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(103, 103, 120, 0.2), 0px 15px 22px 2px rgba(103, 103, 120, 0.14), 0px 6px 28px 5px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z16::before {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(103, 103, 120, 0.2), 0px 16px 24px 2px rgba(103, 103, 120, 0.14), 0px 6px 30px 5px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z17::before {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(103, 103, 120, 0.2), 0px 17px 26px 2px rgba(103, 103, 120, 0.14), 0px 6px 32px 5px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z18::before {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(103, 103, 120, 0.2), 0px 18px 28px 2px rgba(103, 103, 120, 0.14), 0px 7px 34px 6px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z19::before {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(103, 103, 120, 0.2), 0px 19px 29px 2px rgba(103, 103, 120, 0.14), 0px 7px 36px 6px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z20::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(103, 103, 120, 0.2), 0px 20px 31px 3px rgba(103, 103, 120, 0.14), 0px 8px 38px 7px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z21::before {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(103, 103, 120, 0.2), 0px 21px 33px 3px rgba(103, 103, 120, 0.14), 0px 8px 40px 7px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z22::before {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(103, 103, 120, 0.2), 0px 22px 35px 3px rgba(103, 103, 120, 0.14), 0px 8px 42px 7px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z23::before {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(103, 103, 120, 0.2), 0px 23px 36px 3px rgba(103, 103, 120, 0.14), 0px 9px 44px 8px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-paper--color-secondary.smui-accordion__panel--elevation-z24::before {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(103, 103, 120, 0.2), 0px 24px 38px 3px rgba(103, 103, 120, 0.14), 0px 9px 46px 8px rgba(103, 103, 120, 0.12);
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised:first-child, .smui-accordion .smui-accordion__panel.smui-paper--unelevated:first-child {
  border-top-width: 0;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised:last-child, .smui-accordion .smui-accordion__panel.smui-paper--unelevated:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--open, .smui-accordion .smui-accordion__panel.smui-accordion__panel--raised.smui-accordion__panel--open + .smui-accordion__panel, .smui-accordion .smui-accordion__panel.smui-paper--unelevated.smui-accordion__panel--open, .smui-accordion .smui-accordion__panel.smui-paper--unelevated.smui-accordion__panel--open + .smui-accordion__panel {
  border-top-width: 0;
}
.smui-accordion .smui-accordion__panel.smui-paper--outlined:nth-child(1n+2) {
  border-top-width: 0;
}
.smui-accordion .smui-accordion__panel.smui-paper--outlined.smui-accordion__panel--open, .smui-accordion .smui-accordion__panel.smui-paper--outlined.smui-accordion__panel--open + .smui-accordion__panel {
  border-top-width: 1px;
}
.smui-accordion .smui-accordion__panel.smui-paper--rounded::before {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
.smui-accordion .smui-accordion__panel:first-child:not(:last-child), .smui-accordion .smui-accordion__panel:first-child:not(:last-child)::before {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.smui-accordion .smui-accordion__panel:last-child:not(:first-child), .smui-accordion .smui-accordion__panel:last-child:not(:first-child)::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.smui-accordion .smui-accordion__panel:not(:first-child, :last-child), .smui-accordion .smui-accordion__panel:not(:first-child, :last-child)::before {
  border-radius: 0;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__title {
  padding: 16px 24px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__title.smui-accordion__header__title--with-description {
  flex-basis: 30%;
  max-width: 280px;
  box-sizing: border-box;
  padding-inline-end: 0;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__description {
  opacity: 0.48;
  padding: 16px 24px;
  flex-basis: 0;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__icon {
  align-self: center;
  flex-shrink: 1;
  margin: 16px 24px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: 0;
}
.smui-accordion .smui-accordion__panel > .smui-accordion__header .smui-accordion__header__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--disabled > .smui-accordion__header, .smui-accordion .smui-accordion__panel.smui-accordion__panel--non-interactive > .smui-accordion__header {
  cursor: initial;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--disabled > .smui-accordion__header, .smui-accordion .smui-accordion__panel.smui-accordion__panel--disabled > .smui-paper__content {
  opacity: 0.38;
}
.smui-accordion .smui-accordion__panel > .smui-paper__content {
  overflow: hidden;
  transition: height 0.3s 0ms cubic-bezier(0.4, 0, 0.2, 1), padding 0.3s 0ms cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  height: 0;
  padding: 16px 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.smui-accordion .smui-accordion__panel > .smui-paper__content.smui-accordion__content--no-transition {
  transition: none;
}
.smui-accordion .smui-accordion__panel > .smui-paper__content.smui-accordion__content--force-open {
  height: auto;
  padding: 16px 24px;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--opened > .smui-paper__content {
  overflow: visible;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--open {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--open:first-child {
  margin-top: 0;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--open:last-child {
  margin-bottom: 0;
}
.smui-accordion .smui-accordion__panel.smui-accordion__panel--open > .smui-paper__content {
  height: auto;
  padding: 16px 24px;
}

.smui-autocomplete {
  display: inline-block;
}

.smui-autocomplete__menu {
  min-width: 100%;
}

.smui-badge {
  min-height: 24px;
  min-width: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  position: absolute;
  font-size: 13.3333333333px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 2;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 4px;
  padding-right: 4px;
}
.smui-badge.smui-badge--rounded {
  border-radius: 24px;
}
.smui-badge.smui-badge--align-top-start.smui-badge--position-inset, .smui-badge.smui-badge--align-top-start.smui-badge--position-middle, .smui-badge.smui-badge--align-top-middle.smui-badge--position-inset, .smui-badge.smui-badge--align-top-middle.smui-badge--position-middle, .smui-badge.smui-badge--align-top-end.smui-badge--position-inset, .smui-badge.smui-badge--align-top-end.smui-badge--position-middle {
  top: 0;
}
.smui-badge.smui-badge--align-top-start.smui-badge--position-outset, .smui-badge.smui-badge--align-top-middle.smui-badge--position-outset, .smui-badge.smui-badge--align-top-end.smui-badge--position-outset {
  bottom: 100%;
}
.smui-badge.smui-badge--align-bottom-start.smui-badge--position-inset, .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-middle.smui-badge--position-inset, .smui-badge.smui-badge--align-bottom-middle.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-inset, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle {
  bottom: 0;
}
.smui-badge.smui-badge--align-bottom-start.smui-badge--position-outset, .smui-badge.smui-badge--align-bottom-middle.smui-badge--position-outset, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-outset {
  top: 100%;
}
.smui-badge.smui-badge--align-top-end.smui-badge--position-inset, .smui-badge.smui-badge--align-middle-end.smui-badge--position-inset, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-inset {
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
}
[dir=rtl] .smui-badge.smui-badge--align-top-end.smui-badge--position-inset, [dir=rtl] .smui-badge.smui-badge--align-middle-end.smui-badge--position-inset, [dir=rtl] .smui-badge.smui-badge--align-bottom-end.smui-badge--position-inset, .smui-badge.smui-badge--align-top-end.smui-badge--position-inset[dir=rtl], .smui-badge.smui-badge--align-middle-end.smui-badge--position-inset[dir=rtl], .smui-badge.smui-badge--align-bottom-end.smui-badge--position-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-end.smui-badge--position-middle, .smui-badge.smui-badge--align-middle-end.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle {
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
}
[dir=rtl] .smui-badge.smui-badge--align-top-end.smui-badge--position-middle, [dir=rtl] .smui-badge.smui-badge--align-middle-end.smui-badge--position-middle, [dir=rtl] .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle, .smui-badge.smui-badge--align-top-end.smui-badge--position-middle[dir=rtl], .smui-badge.smui-badge--align-middle-end.smui-badge--position-middle[dir=rtl], .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-end.smui-badge--position-outset, .smui-badge.smui-badge--align-middle-end.smui-badge--position-outset, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-outset {
  /* @noflip */ /*rtl:ignore*/
  left: 100%;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-badge.smui-badge--align-top-end.smui-badge--position-outset, [dir=rtl] .smui-badge.smui-badge--align-middle-end.smui-badge--position-outset, [dir=rtl] .smui-badge.smui-badge--align-bottom-end.smui-badge--position-outset, .smui-badge.smui-badge--align-top-end.smui-badge--position-outset[dir=rtl], .smui-badge.smui-badge--align-middle-end.smui-badge--position-outset[dir=rtl], .smui-badge.smui-badge--align-bottom-end.smui-badge--position-outset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 100%;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-start.smui-badge--position-inset, .smui-badge.smui-badge--align-middle-start.smui-badge--position-inset, .smui-badge.smui-badge--align-bottom-start.smui-badge--position-inset {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-badge.smui-badge--align-top-start.smui-badge--position-inset, [dir=rtl] .smui-badge.smui-badge--align-middle-start.smui-badge--position-inset, [dir=rtl] .smui-badge.smui-badge--align-bottom-start.smui-badge--position-inset, .smui-badge.smui-badge--align-top-start.smui-badge--position-inset[dir=rtl], .smui-badge.smui-badge--align-middle-start.smui-badge--position-inset[dir=rtl], .smui-badge.smui-badge--align-bottom-start.smui-badge--position-inset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-start.smui-badge--position-middle, .smui-badge.smui-badge--align-middle-start.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle {
  /* @noflip */ /*rtl:ignore*/
  left: 0;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
}
[dir=rtl] .smui-badge.smui-badge--align-top-start.smui-badge--position-middle, [dir=rtl] .smui-badge.smui-badge--align-middle-start.smui-badge--position-middle, [dir=rtl] .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle, .smui-badge.smui-badge--align-top-start.smui-badge--position-middle[dir=rtl], .smui-badge.smui-badge--align-middle-start.smui-badge--position-middle[dir=rtl], .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 0;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-start.smui-badge--position-outset, .smui-badge.smui-badge--align-middle-start.smui-badge--position-outset, .smui-badge.smui-badge--align-bottom-start.smui-badge--position-outset {
  /* @noflip */ /*rtl:ignore*/
  left: initial;
  /* @noflip */ /*rtl:ignore*/
  right: 100%;
}
[dir=rtl] .smui-badge.smui-badge--align-top-start.smui-badge--position-outset, [dir=rtl] .smui-badge.smui-badge--align-middle-start.smui-badge--position-outset, [dir=rtl] .smui-badge.smui-badge--align-bottom-start.smui-badge--position-outset, .smui-badge.smui-badge--align-top-start.smui-badge--position-outset[dir=rtl], .smui-badge.smui-badge--align-middle-start.smui-badge--position-outset[dir=rtl], .smui-badge.smui-badge--align-bottom-start.smui-badge--position-outset[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */ /*rtl:ignore*/
  left: 100%;
  /* @noflip */ /*rtl:ignore*/
  right: initial;
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-start.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%);
}
[dir=rtl] .smui-badge.smui-badge--align-top-start.smui-badge--position-middle, .smui-badge.smui-badge--align-top-start.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(50%) translateY(-50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-middle.smui-badge--position-middle {
  transform-origin: center;
  transform: translateY(-50%);
}
[dir=rtl] .smui-badge.smui-badge--align-top-middle.smui-badge--position-middle, .smui-badge.smui-badge--align-top-middle.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateY(-50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-top-end.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(50%) translateY(-50%);
}
[dir=rtl] .smui-badge.smui-badge--align-top-end.smui-badge--position-middle, .smui-badge.smui-badge--align-top-end.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(-50%) translateY(-50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-middle-start.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(-50%);
}
[dir=rtl] .smui-badge.smui-badge--align-middle-start.smui-badge--position-middle, .smui-badge.smui-badge--align-middle-start.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-middle-end.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(50%);
}
[dir=rtl] .smui-badge.smui-badge--align-middle-end.smui-badge--position-middle, .smui-badge.smui-badge--align-middle-end.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(-50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(-50%) translateY(50%);
}
[dir=rtl] .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-start.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(50%) translateY(50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-bottom-middle.smui-badge--position-middle {
  transform-origin: center;
  transform: translateY(50%);
}
[dir=rtl] .smui-badge.smui-badge--align-bottom-middle.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-middle.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateY(50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle {
  transform-origin: center;
  transform: translateX(50%) translateY(50%);
}
[dir=rtl] .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle, .smui-badge.smui-badge--align-bottom-end.smui-badge--position-middle[dir=rtl] {
  /*rtl:begin:ignore*/
  transform: translateX(-50%) translateY(50%);
  /*rtl:end:ignore*/
}

.smui-badge.smui-badge--color-primary {
  background-color: #ff3e00;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff3e00);
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.smui-badge.smui-badge--color-secondary {
  background-color: #676778;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #676778);
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}

.smui-bottom-app-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  --smui-bottom-app-bar--fab-offset: 0px;
}
.smui-bottom-app-bar.smui-bottom-app-bar--fixed, .smui-bottom-app-bar.smui-bottom-app-bar--standard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  padding: 16px 16px;
  height: 56px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  overflow: visible;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section .mdc-icon-button {
  margin-top: -12px;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section .mdc-fab {
  position: relative;
  top: calc(var(--smui-bottom-app-bar--fab-offset, 0px) - 44px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section:first-child {
  justify-content: start;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section:first-child .mdc-icon-button:first-child {
  margin-inline-start: -12px;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section:last-child {
  justify-content: end;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section:last-child .mdc-icon-button:last-child {
  margin-inline-end: -12px;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset {
  background: radial-gradient(72px 72px at 50% var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #ff3e00 36.36px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset:first-child {
  background: radial-gradient(72px 72px at 52px var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #ff3e00 36.36px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset:first-child .mdc-fab {
  margin-inline-start: 8px;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset:last-child {
  background: radial-gradient(72px 72px at calc(100% - 52px) var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #ff3e00 36.36px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset:last-child .mdc-fab {
  margin-inline-end: 8px;
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset.smui-paper--color-secondary {
  background: radial-gradient(72px 72px at 50% var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #676778 36.36px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset.smui-paper--color-secondary:first-child {
  background: radial-gradient(72px 72px at 52px var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #676778 36.36px);
}
.smui-bottom-app-bar > .smui-bottom-app-bar__section.smui-paper.smui-bottom-app-bar__section--fab-inset.smui-paper--color-secondary:last-child {
  background: radial-gradient(72px 72px at calc(100% - 52px) var(--smui-bottom-app-bar--fab-offset, 0px), rgba(0, 0, 0, 0) 35.64px, #676778 36.36px);
}

.smui-bottom-app-bar--fixed-adjust {
  padding-bottom: 56px;
}

.smui-bottom-app-bar--fixed-adjust .mdc-snackbar,
.smui-bottom-app-bar--standard-adjust .mdc-snackbar {
  transform: translateY(calc(-56px - var(--smui-bottom-app-bar--fab-offset, 0)));
}
.smui-bottom-app-bar--fixed-adjust.smui-bottom-app-bar--with-fab .mdc-snackbar,
.smui-bottom-app-bar--standard-adjust.smui-bottom-app-bar--with-fab .mdc-snackbar {
  transform: translateY(calc(-84px - var(--smui-bottom-app-bar--fab-offset, 0)));
}

/*
"What the fuck is this nonsense?" (I hear you asking after reading the
following styles.)

That's a great question.

In Google Chrome (the worst browser now that IE is dead), the background style
on the bar sections doesn't get applied, so the bar is... just transparent.
Super weird. BUUUT, if an animation runs inside the bar (like a ripple on
button click), the background is rendered again.

"Well why not just run the animation once on component load?"

Also a great quesiton.

Even if the background is rendered, if it is scrolled *down* to below the
fold, then scrolled back into view, it will be transparent again.

"Won't this have an impact on power consumption/battery life?"

Yes.
*/
@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and (not (-moz-appearance: none)) {
  .smui-bottom-app-bar.smui-bottom-app-bar--standard > .smui-bottom-app-bar__section::after,
  .smui-bottom-app-bar.smui-bottom-app-bar--fixed > .smui-bottom-app-bar__section::after {
    display: block;
    content: " ";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 10px;
    width: 10px;
    pointer-events: none;
    background-color: #000;
    opacity: 0;
    animation: 60s linear 0s infinite chrome-fix;
  }
  @keyframes chrome-fix {
    0% {
      bottom: 0;
    }
    50% {
      bottom: 46px;
    }
    100% {
      bottom: 0px;
    }
  }
}